import union from 'lodash/union';
import flatten from 'lodash/flatten';
import AgenliteAPIClient from '~/api/index';
import SilkroadAPIClient from '~/api/silkroad';
import transformArrayMixin from '~/store/modules/transform-array';
import VPNonTraderAPIClient from '~/api/vpnontrader';

const NEO_VP_CATALOG_CONFIG_KEY = 'o2o-saas/promotion_media_vp_catalog_config';
const NEO_BUKA20_VP_CATALOG_CONFIG_KEY = 'o2o-saas/buka20-promotion_media_vp_catalog_config';

const vpCatalogForm = {
  color: 0,
  business_name: '',
  phone: '',
  slogan: '',
  products: [],
  catalog_type: '',
};

const agentSellingProductForm = {
  product_id: 0,
  selling_price: 0,
};

const convertToBuka10Product = buka20Product => {
  // Only return used fields.
  return {
    id: buka20Product.id,
    operator: {
      name: buka20Product.vendor.name,
    },
    partner_package: {
      package: {
        name: buka20Product.name,
        price: +buka20Product.price,
      },
    },
  };
};

const initialState = {
  vpCatalogs: [],
  vpCatalogForm: { ...vpCatalogForm },
  vpCatalogConfigProductList: { ...agentSellingProductForm },
  agentSellingProductForm: {},
  agentSellingProducts: {},
  virtualProducts: {},
  catalogType: '',
  buka20Whitelisted: false,
};

const getters = {
  vpCatalogs: state => state.vpCatalogs,
  vpCatalogConfigProductList: state => state.vpCatalogConfigProductList,
  getVpCatalog: state => id => state.vpCatalogs.find(e => e.id === id),
  getVpCatalogForm: state => state.vpCatalogForm,
  getVpCatalogFormProductByVpId: state => id => state.vpCatalogForm.products.find(e => e.virtual_product_id === id),
  getVpCatalogFormProductList:
    (state, getters) =>
    ({ type, provider }) => {
      const configProductList =
        type === 'multiple'
          ? state.vpCatalogConfigProductList.multiple?.[provider]
          : state.vpCatalogConfigProductList[provider];
      return (configProductList || [])
        .map(key => {
          const intKey = Number.parseInt(key, 10);
          const virtualProduct = state.virtualProducts[intKey] || {};
          const agentSellingProduct = state.agentSellingProducts[intKey] || {};
          const savedVpCatalogProduct = getters.getVpCatalogFormProductByVpId(intKey) || {};

          const useRecommendedPrice = !savedVpCatalogProduct.price && !!agentSellingProduct.recommended_selling_price;
          const savedRecommendedPrice =
            !!savedVpCatalogProduct.price &&
            agentSellingProduct.recommended_selling_price === savedVpCatalogProduct.price;
          const showRecommendedTag =
            !agentSellingProduct.selling_price && (useRecommendedPrice || savedRecommendedPrice);
          return {
            ...agentSellingProduct,
            name: virtualProduct?.partner_package?.package?.name || '',
            virtual_product_id: intKey,
            price:
              savedVpCatalogProduct.price ||
              agentSellingProduct.selling_price ||
              agentSellingProduct.recommended_selling_price,
            tag: showRecommendedTag ? 'recommended' : '',
          };
        })
        .filter(e => !!e.id);
    },
  getMultipleVpCatalogFormProductList: (state, getters) => {
    return Object.keys(state.vpCatalogConfigProductList.multiple || []).reduce((list, provider) => {
      list[provider] = getters.getVpCatalogFormProductList({ type: 'multiple', provider });
      return list;
    }, {});
  },
  getVirtualProducts: state => state.virtualProducts,
  getVirtualProductById: state => id => Object.values(state.virtualProducts).find(e => e.id === id),
  getAgentSellingProducts: state => state.agentSellingProducts,
  getAgentSellingProductForm: state => state.agentSellingProductForm,
};

const actions = {
  async initVpCatalog({ state, dispatch }) {
    await dispatch('retrieveBuka20WhitelistStatus');
    await dispatch('retrieveVpCatalogProductListConfig');
    if (state.buka20Whitelisted) {
      await dispatch('retrieveBuka20VirtualProductsFromConfig');
    } else {
      await dispatch('retrieveVirtualProductsFromConfig');
    }
    await dispatch('retrieveAgentSellingProductsFromConfig');
  },
  async retrieveBuka20WhitelistStatus({ commit }) {
    const response = await AgenliteAPIClient.getWallet20WhitelistStatus('prepaid-phone-plan');
    commit('setBuka20Whitelisted', !!response?.data?.whitelisted);
  },
  async retrieveVpCatalogProductListConfig({ state, commit }) {
    const key = state.buka20Whitelisted ? NEO_BUKA20_VP_CATALOG_CONFIG_KEY : NEO_VP_CATALOG_CONFIG_KEY;

    const { data } = await AgenliteAPIClient.fetchNeoConfigs([key]);
    commit('setVpCatalogConfigProductList', data[0].data);
  },
  async retrieveVirtualProductsFromConfig({ state, commit }) {
    const vpCatalogConfigProductList = {
      [state.catalogType]: state.vpCatalogConfigProductList[state.catalogType],
    };
    const singleProviderVpPackageIds = Object.values(vpCatalogConfigProductList).filter(list => Array.isArray(list));
    const multipleProviderVpPackageIds =
      state.catalogType === 'multiple' ? Object.values(state.vpCatalogConfigProductList?.multiple || []) : [];
    const vpPackageIds = union(...singleProviderVpPackageIds, ...multipleProviderVpPackageIds);
    const chunkedPayloads = transformArrayMixin.methods.splitArraytoChunk(vpPackageIds, 20);
    const aggregateResponses = await Promise.all(
      chunkedPayloads.map(async payload => {
        const aggregatePayload = payload.reduce((obj, id) => {
          obj[id] = {
            method: 'GET',
            path: `/phone-credits/prepaid-products/${id}`,
          };
          return obj;
        }, {});
        return await AgenliteAPIClient.postAggregate(aggregatePayload);
      })
    );

    const result = aggregateResponses.reduce(
      (obj, response) => {
        obj.meta = { ...obj.meta, ...response.meta };
        obj.data = { ...obj.data, ...response.data };
        obj.error = { ...obj.error, ...response.error };
        return obj;
      },
      { data: {}, error: {}, meta: {} }
    );

    const { data, error } = result;
    if (Object.keys(error).length) throw new Error('ERROR_AGGREGATE');
    const virtualProducts = Object.keys(data).reduce((products, id) => {
      const virtualProduct = data?.[id];
      if (virtualProduct.id) products[id] = virtualProduct;
      return products;
    }, {});
    commit('setVirtualProducts', virtualProducts);
  },
  async retrieveBuka20VirtualProductsFromConfig({ state, commit }) {
    let productIDs = [];
    if (state.catalogType === 'multiple') {
      productIDs = flatten(Object.values(state.vpCatalogConfigProductList?.multiple || []));
    } else {
      productIDs = state.vpCatalogConfigProductList[state.catalogType] || [];
    }

    const chunkedProductIds = transformArrayMixin.methods.splitArraytoChunk(productIDs, 20);
    const batchedProducts = await Promise.all(
      chunkedProductIds.map(
        async productIds =>
          await VPNonTraderAPIClient.getCustomerProducts({
            product_ids: productIds,
            product_type: 'prepaid-phone-plan',
          })
      )
    );

    const products = batchedProducts.reduce(
      (acc, product) => {
        acc.customer_products = [...acc.customer_products, ...product.customer_products];
        return acc;
      },
      { customer_products: [] }
    );

    const buka10Products = products.customer_products.reduce((acc, product) => {
      acc[product.id] = convertToBuka10Product(product);
      return acc;
    }, {});

    commit('setVirtualProducts', buka10Products);
  },
  async retrieveAgentSellingProductsFromConfig({ state, commit }) {
    const vpPackageIds = Object.keys(state.virtualProducts);
    const chunkedPayloads = transformArrayMixin.methods.splitArraytoChunk(vpPackageIds, 20);
    const aggregateResponses = await Promise.all(
      chunkedPayloads.map(async payload => {
        const aggregatePayload = payload.reduce((obj, pId) => {
          const productId = state.virtualProducts[pId]?.id;
          obj[pId] = {
            method: 'GET',
            path: `/agent-selling-products?product_id=${productId}&product_type=data_plan`,
          };
          return obj;
        }, {});
        return await AgenliteAPIClient.postAggregate(aggregatePayload);
      })
    );
    const result = aggregateResponses.reduce(
      (obj, response) => {
        obj.meta = { ...obj.meta, ...response.meta };
        obj.data = { ...obj.data, ...response.data };
        obj.error = { ...obj.error, ...response.error };
        return obj;
      },
      { data: {}, error: {}, meta: {} }
    );

    const { data, error } = result;
    if (Object.keys(error).length) throw new Error('ERROR_AGGREGATE');
    const agentSellingProducts = Object.keys(data).reduce((products, pId) => {
      const agentSellingProduct = data?.[pId]?.[0] || {};
      if (agentSellingProduct.id) products[pId] = agentSellingProduct;
      return products;
    }, {});
    commit('setAgentSellingProducts', agentSellingProducts);
  },
  async updateAgentSellingProductPrice({ state, getters, commit }) {
    const { virtual_product_id: vpId } = state.agentSellingProductForm;
    const { data } = await AgenliteAPIClient.updateAgentSellingProduct({
      id: state.agentSellingProductForm.id,
      product_type: state.agentSellingProductForm.product_typey,
      product_id: state.agentSellingProductForm.product_id,
      selling_price: state.agentSellingProductForm.selling_price,
    });
    commit('updateAgentSellingProduct', { key: vpId, payload: data });
    const editedVirtualProduct = getters.getVirtualProducts[vpId];
    const vpCatalogProduct = {
      virtual_product_id: vpId,
      name: data.name,
      price: data.selling_price,
      provider_type: editedVirtualProduct.operator?.name || '',
    };
    commit('updateVpCatalogFormProduct', vpCatalogProduct);
  },
  async retrieveVpCatalogs({ commit }) {
    const { data } = await AgenliteAPIClient.retrievePromotionMediaVpCatalogs();
    commit('setVpCatalogs', data);
  },
  async saveVpCatalogProduct(_, { id, payload }) {
    const saveFunc = id
      ? AgenliteAPIClient.updatePromotionMediaVpCatalog
      : AgenliteAPIClient.createPromotionMediaVpCatalog;
    const savePayload = id ? [id, payload] : [payload];
    const response = await saveFunc(...savePayload);
    return response;
  },
  async prefillVpCatalogForm({ commit, rootGetters }) {
    let businessName = '';
    try {
      const [silkroadMe, saasMe] = await Promise.allSettled([
        SilkroadAPIClient.me(),
        AgenliteAPIClient.fetchSaasUser(),
      ]);
      businessName = saasMe?.value?.data?.business_name || silkroadMe?.value?.data?.address?.name || '';
    } catch {
    } finally {
      commit('setVpCatalogForm', {
        ...vpCatalogForm,
        business_name: businessName,
        phone: rootGetters.globalUser?.phone || '',
      });
    }
  },
  async retrieveAgentSellingProduct({ commit }, payload) {
    const { data: agentSellingProducts } = await AgenliteAPIClient.getAgentSellingProducts({
      product_id: payload.productId,
      product_type: payload.productType,
      nominal: payload.nominal,
    });
    commit('setAgentSellingProducts', agentSellingProducts);
  },
  async upsertAgentSellingProductPrice(_, payload) {
    await AgenliteAPIClient.upsertAgentSellingProduct({
      product_type: payload.productType,
      product_id: payload.productId,
      selling_price: payload.sellingPrice,
      profit: payload.profit,
    });
  },
};

const mutations = {
  setVpCatalogConfigProductList(state, config) {
    state.vpCatalogConfigProductList = config;
  },
  setVpCatalogForm(state, payload) {
    state.vpCatalogForm = payload;
  },
  setCatalogType(state, payload) {
    state.catalogType = payload;
  },
  resetVpCatalogForm(state, _) {
    state.vpCatalogForm = { ...vpCatalogForm };
  },
  setVpCatalogs(state, payload) {
    state.vpCatalogs = payload;
  },
  setVirtualProducts(state, products) {
    state.virtualProducts = products;
  },
  setAgentSellingProductForm(state, payload) {
    state.agentSellingProductForm = payload;
  },
  setAgentSellingProducts(state, products) {
    state.agentSellingProducts = products;
  },
  updateAgentSellingProduct(state, { key, payload }) {
    state.agentSellingProducts[key] = payload;
  },
  updateVpCatalogFormProduct(state, payload) {
    const productIdx = state.vpCatalogForm.products.findIndex(e => e.virtual_product_id === payload.virtual_product_id);
    if (productIdx > -1) {
      state.vpCatalogForm.products[productIdx] = payload;
    } else {
      state.vpCatalogForm.products.push(payload);
    }
  },
  setBuka20Whitelisted(state, whitelisted) {
    state.buka20Whitelisted = whitelisted;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
