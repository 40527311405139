import VPNonTraderAPIClient from '~/api/vpnontrader';

const initialState = {};

const getters = {};

const actions = {
  async createBuka20Transaction({ dispatch }, payload) {
    let response;
    try {
      dispatch('showAppLoading');
      response = await VPNonTraderAPIClient.createCustomerTransaction(payload);
    } catch (error) {
      const message = error?.errors?.length > 0 ? error.errors[0].message : 'Terjadi kesalahan, silakan coba lagi.';
      dispatch('showFlashAlert', { message, type: 'warning' });
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
};

const mutations = {};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
