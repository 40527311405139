import Client from '~/types/api/api-module';
import ApiOptions from '~/api/v4/api-options';
import RequestBuilder from '~/api/request_builder';
import { APIResponse } from '~/types/api/api-response';

const PAYOUT_URL = `${process.env.API_V4_HOST}/payouts`;

export default Client.wrap({
  Public: {
    payTopupBukadanaTransaction(userId, requestPayload) {
      const requestHeaders = {
        ...RequestBuilder.getOtpHeader(this.getOtp(userId).key),
      };
      return this.v4.payout.topupDana(userId, requestHeaders, requestPayload);
    },
    retrievePayoutTransactions() {
      return this.v4.payout.retrieveTransactions();
    },
    async makePayoutTransaction(userId, payload) {
      const { OTPOptions, requestPayload } = payload;
      let response: APIResponse<any>;
      try {
        const requestHeaders = {
          ...RequestBuilder.getOtpHeader(this.getOtp(userId)?.key, OTPOptions),
        };
        response = await this.v4.payout.postPayoutTransaction(requestPayload, requestHeaders);
        this.setOtpKey(userId, response?.meta?.otp_key ?? '');
      } catch (error) {
        this.setOtpKey(userId, error?.meta?.otp_key ?? '');
        throw error;
      }
      return response;
    },
  },
  Private: {
    retrieveTransactions() {
      return this.get(`${PAYOUT_URL}/transactions?states[]=remitted&&states[]=paid`, 'user', new ApiOptions());
    },
    retrieveTransaction(id) {
      return this.get(`${PAYOUT_URL}/transactions/${id}`, 'user', new ApiOptions());
    },
    createPayoutTransaction(requestPayload) {
      return this.post(`${PAYOUT_URL}/transactions`, 'user', new ApiOptions({ requestPayload }));
    },
    postPayoutTransaction(requestPayload, requestHeaders) {
      return this.post(`${PAYOUT_URL}/transactions`, 'user', new ApiOptions({ requestPayload, requestHeaders }));
    },
    topupDana(_userId, requestHeaders, requestPayload) {
      return this.post(`${PAYOUT_URL}/dana/topup`, 'user', new ApiOptions({ requestHeaders, requestPayload }));
    },
  },
});
