import { differenceInDays } from 'date-fns';
import AgenliteAPIClient from '~/api/index';
import PaymentDomainAPIClient from '~/api/payment-domain';

const PIN_CREATION_LOCAL_STORAGE = 'pin_creation';
const NPS_SURVEY_LOCAL_STORAGE = 'digital_send_detail_nps_survey';

function setDefaultPinCreationLocalStorage() {
  localStorage.setItem(
    PIN_CREATION_LOCAL_STORAGE,
    JSON.stringify({
      last_appearance: new Date(),
      skip_times: 0,
    })
  );
}

function setDefaultNpsSurveyLocalStorage() {
  localStorage.setItem(
    NPS_SURVEY_LOCAL_STORAGE,
    JSON.stringify({
      last_appearance: new Date(),
      shown_times: 0,
      last_month_filled: 0,
    })
  );
}

interface TransactionAmount {
  total?: number;
}

interface StateTransaction {
  invoice_group_number?: string;
  remote_id?: string;
  mitra_id?: string;
  status?: string;
  amount?: TransactionAmount;
}

interface Invoices {
  status?: string;
}

interface StateInvoice {
  invoice_group_no?: string;
  invoices?: Invoices[];
}

interface StateDailyCheckinProgress {
  eventId?: string;
  claimed?: number;
}

interface StateConfigNpsSurvey {
  active?: boolean;
  max_gap_days?: number;
  max_shown_times?: number;
  transaction_states?: string[];
}

const initialState = {
  transaction: {} as StateTransaction,
  invoice: {} as StateInvoice,
  loading: true,

  tieringMissionProgress: {},
  tieringMissionEvents: [],
  dailyCheckinProgress: {} as StateDailyCheckinProgress,
  leaderboardProgress: {},

  leaderboardEventList: [],

  configPinCreation: {},
  pinCreationGapDays: 0,
  pinCreationSkipTimes: 0,

  configNpsSurvey: {} as StateConfigNpsSurvey,
  npsSurveyGapDays: 0,
  npsSurveyShownTimes: 0,
  npsSurveyLastMonthFilled: 0,

  userSegments: [],
};

const getters = {
  getDigitalSendTransaction(state) {
    return state.transaction;
  },
  getDigitalSendTransactionLoading(state) {
    return state.loading;
  },
  getInvoiceDetails(state) {
    return state.invoice;
  },
  getTieringMissionProgress(state) {
    return state.tieringMissionProgress;
  },
  getTieringMissionIsNonReward(state) {
    return !!(state.tieringMissionEvents?.[0]?.reward_name && state.tieringMissionEvents?.[0]?.reward_name !== '');
  },
  getTieringMissionGrandPrize(state, getters) {
    // grand prize non reward
    if (getters.getTieringMissionIsNonReward) {
      return state.tieringMissionEvents?.[0]?.reward_name;
    }
    return getters.getTieringMissionProgress.total_prize_pool || 0;
  },
  getDailyCheckinProgress(state) {
    return state.dailyCheckinProgress;
  },
  getLeaderboardProgress(state) {
    return state.leaderboardProgress;
  },
  getLeaderboardEventActive(state) {
    const eventActiveList = state.leaderboardEventList.filter(
      ({ state: stateEvent, blacklisted }) => stateEvent === 'active' && !blacklisted
    );

    const mapEventActiveList = eventActiveList
      .sort((a, b) => {
        const x = a.event_type;
        const y = b.event_type;
        if (x < y) return 1;
        if (x > y) return -1;
        return 0;
      })
      .map(event => ({
        ...event,
        id: `${event.id}`,
      }));

    return mapEventActiveList.length > 0 ? mapEventActiveList[0] : null;
  },

  getUserSegments(state) {
    return state.userSegments;
  },

  isShowPinCreationOnTransactionDetail(state) {
    const isValidConfigPinCreation = !!(state.configPinCreation.max_gap_days && state.configPinCreation.max_skip_times);
    const getPinCreationGapDays = Number(state.pinCreationGapDays);
    const getConfigPinCreationMaxGapDays = state.configPinCreation.max_gap_days
      ? Number(state.configPinCreation.max_gap_days)
      : 0;
    const isShowPinCreation = getPinCreationGapDays > getConfigPinCreationMaxGapDays;
    const isShowEntryPointTransactionDetail = state.configPinCreation.entry_point?.on_transaction_detail || false;

    return isValidConfigPinCreation && isShowPinCreation && isShowEntryPointTransactionDetail === true;
  },
  getPayloadPinCreationOnTransactionDetail(state) {
    const getPinCreationSkipTimes = Number(state.pinCreationSkipTimes);
    const getConfigPinCreationMaxSkip = state.configPinCreation.max_skip_times
      ? Number(state.configPinCreation.max_skip_times)
      : 0;
    const isSkippablePinCreation = getPinCreationSkipTimes < getConfigPinCreationMaxSkip;

    const phoneNumber = state.configPinCreation.customer_service?.phone_number || '628175720770';
    const templateMessage =
      state.configPinCreation.customer_service?.template_message ||
      'Hai admin, saya punya pertanyaan tentang pembuatan PIN. Boleh minta bantuannya?';
    return {
      title: 'Pakai PIN biar saldo kamu lebih aman',
      subtitle: 'Biar saldo kamu lebih aman. Login juga gak perlu nunggu OTP',
      skippable: isSkippablePinCreation,
      phone_number: phoneNumber,
      template_message: templateMessage,
    };
  },

  isActiveNpsSurveyOnTransactionDetail(state) {
    return state.configNpsSurvey.active === true;
  },
  isShowNpsSurveyOnTransactionDetail(state) {
    const {
      configNpsSurvey: {
        active,
        max_gap_days: maxGapDays,
        max_shown_times: maxShownTimes,
        transaction_states: transactionStates,
      },
      npsSurveyGapDays,
      npsSurveyShownTimes,
      npsSurveyLastMonthFilled,
    } = state;
    return transactionState => {
      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      const isActiveNpsSurvey = active === true;
      const isNotFilledYetCurrentMonth = currentMonth !== npsSurveyLastMonthFilled;
      const isValidConfigNpsSurvey = !!(maxGapDays && maxShownTimes && transactionStates?.length > 0);
      const getNpsSurveyGapDays = Number(npsSurveyGapDays);
      const getConfigNpsSurveyMaxGapDays = maxGapDays ? Number(maxGapDays) : 0;
      const getNpsSurveyShownTimes = Number(npsSurveyShownTimes);
      const getConfigNpsSurveyShownTimes = maxShownTimes ? Number(maxShownTimes) : 0;
      const isShowNpsSurvey =
        getNpsSurveyGapDays > getConfigNpsSurveyMaxGapDays &&
        getNpsSurveyShownTimes <= getConfigNpsSurveyShownTimes &&
        transactionStates.includes(transactionState);

      return isActiveNpsSurvey && isNotFilledYetCurrentMonth && isValidConfigNpsSurvey && isShowNpsSurvey;
    };
  },
};

const actions = {
  async retrieveDigitalSendTransaction({ dispatch, commit }, requestPayload) {
    try {
      if (!requestPayload.backgroundRetrieve) {
        commit('setDigitalSendTransactionLoading', true);
      }

      const response = await AgenliteAPIClient.getDigitalSendTransaction(requestPayload.id);
      commit('setDigitalSendTransaction', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data transaksi';
      dispatch('catchError', { error, message });
      throw error;
    } finally {
      commit('setDigitalSendTransactionLoading', false);
    }
  },

  async retrieveInvoiceDetails({ dispatch, commit }, requestPayload) {
    try {
      const response = await PaymentDomainAPIClient.getInvoiceDetails(requestPayload.invoice_group_number);
      commit('setInvoiceDetails', response);
    } catch (error) {
      const message = 'Gagal mendapatkan data transaksi';
      dispatch('catchError', { error, message });
      throw error;
    }
  },

  // tiering mission
  async retrieveTieringMissionProgress({ dispatch, commit }) {
    try {
      const { data } = await AgenliteAPIClient.getCompetitionTieringMissionProgress();
      commit('setTieringMissionProgress', data.progress);
      const { data: dataEvent } = await AgenliteAPIClient.getCompetitionTieringMissionEvents(`${data.progress.id}`);
      commit('setTieringMissionEvents', dataEvent.events);
    } catch (error) {
      dispatch('catchError', {
        error,
        message: 'Gagal mendapatkan data tiering mission',
        noFlashAlert: true,
      });
      throw error;
    }
  },

  // daily checkin
  async retrieveDailyCheckinProgress({ dispatch, commit }, transactionId) {
    try {
      const { data } = await AgenliteAPIClient.getDailyCheckinEvent();
      const eventId = data.id;

      if (!eventId) return;

      const { data: progress } = await AgenliteAPIClient.getDailyCheckinProgress(eventId);
      let count = 0;
      for (const reward of progress.rewards) {
        if (reward.state === 'claimed') {
          count += 1;
        }

        if (`${reward.transaction_id}` === `${transactionId}`) {
          commit('setDailyCheckinProgress', {
            eventId,
            claimed: count,
          });
          break;
        }
      }
    } catch (error) {
      dispatch('catchError', {
        error,
        message: 'Gagal mendapatkan data daily checkin',
        noFlashAlert: true,
      });
      throw error;
    }
  },

  // leaderboard
  async retrieveLeaderboardProgress({ dispatch, commit, getters }) {
    try {
      let eventId = '';
      const { data } = await AgenliteAPIClient.getCompetitionLeaderboardEvents();
      commit(
        'setLeaderboardEventList',
        data.map(({ event }) => ({ ...event }))
      );
      eventId = getters.getLeaderboardEventActive?.id ?? '';

      if (eventId !== '') {
        const { data: details } = await AgenliteAPIClient.getCompetitionLeaderboardProgress(eventId);
        if (details.ranks.length > 0 && (details.user_rank || details.user_rank === null)) {
          commit('setLeaderboardProgress', {
            eventId,
            prize: details.ranks[0].prize,
            rank: details.user_rank ? details.user_rank : '100+',
          });
        }
      }
    } catch (error) {
      dispatch('catchError', {
        error,
        message: 'Gagal mendapatkan data leaderboard',
        noFlashAlert: true,
      });
      throw error;
    }
  },

  // user segments
  async retrieveUserSegments({ commit, dispatch }) {
    try {
      const { data } = await AgenliteAPIClient.retrieveUserSegments();
      commit('setUserSegments', data);
    } catch (error) {
      dispatch('catchError', {
        error,
        message: 'Gagal mendapatkan data leaderboard',
        noFlashAlert: true,
      });
      throw error;
    }
  },

  // PIN creation
  async getPINStatus() {
    try {
      const { data } = await AgenliteAPIClient.retrievePinStatus();
      return data.status === 'active';
    } catch {
      return false;
    }
  },
  getPinCreationLocalStorage({ commit }) {
    try {
      const { last_appearance: lastAppearance, skip_times: skipTimes } = JSON.parse(
        localStorage.getItem(PIN_CREATION_LOCAL_STORAGE) || ''
      );
      const gapDays = differenceInDays(new Date(), lastAppearance);
      commit('setPinCreationGapDays', Number(gapDays));
      commit('setPinCreationSkipTimes', Number(skipTimes));
    } catch {
      setDefaultPinCreationLocalStorage();
      commit('setPinCreationGapDays', 0);
      commit('setPinCreationSkipTimes', 0);
    }
  },
  updatePinCreationLastAppearance() {
    try {
      const { skip_times: skipTimes } = JSON.parse(localStorage.getItem(PIN_CREATION_LOCAL_STORAGE) || '');
      localStorage.setItem(
        PIN_CREATION_LOCAL_STORAGE,
        JSON.stringify({
          last_appearance: new Date(),
          skip_times: skipTimes,
        })
      );
    } catch {
      setDefaultPinCreationLocalStorage();
    }
  },
  updatePinCreationSkipTimes() {
    try {
      const { last_appearance: lastAppearance, skip_times: skipTimes } = JSON.parse(
        localStorage.getItem(PIN_CREATION_LOCAL_STORAGE) || ''
      );
      localStorage.setItem(
        PIN_CREATION_LOCAL_STORAGE,
        JSON.stringify({
          last_appearance: lastAppearance,
          skip_times: Number(skipTimes) + 1,
        })
      );
    } catch {
      setDefaultPinCreationLocalStorage();
    }
  },

  // NPS Survey
  getNpsSurveyLocalStorage({ commit, dispatch }) {
    try {
      const {
        last_appearance: lastAppearance,
        shown_times: shownTimes,
        last_month_filled: lastMonthFilled,
      } = JSON.parse(localStorage.getItem(NPS_SURVEY_LOCAL_STORAGE) || '');
      const gapDays = differenceInDays(new Date(), lastAppearance);
      commit('setNpsSurveyGapDays', Number(gapDays));
      commit('setNpsSurveyShownTimes', Number(shownTimes));
      commit('setNpsSurveyLastMonthFilled', Number(lastMonthFilled));

      // reset shown time when new month
      if (new Date(lastAppearance).getMonth() !== new Date().getMonth()) {
        commit('setNpsSurveyShownTimes', Number(0));
        dispatch('updateNpsSurveyShownTimes', {
          isResetShownTimes: true,
        });
      }
    } catch {
      setDefaultNpsSurveyLocalStorage();
      commit('setNpsSurveyGapDays', 0);
      commit('setNpsSurveyShownTimes', 0);
      commit('setNpsSurveyLastMonthFilled', 0);
    }
  },
  updateNpsSurveyLastAppearance() {
    try {
      const { shown_times: shownTimes, last_month_filled: lastMonthFilled } = JSON.parse(
        localStorage.getItem(NPS_SURVEY_LOCAL_STORAGE) || ''
      );
      localStorage.setItem(
        NPS_SURVEY_LOCAL_STORAGE,
        JSON.stringify({
          last_appearance: new Date(),
          shown_times: shownTimes,
          last_month_filled: lastMonthFilled || 0,
        })
      );
    } catch {
      setDefaultNpsSurveyLocalStorage();
    }
  },
  updateNpsSurveyShownTimes(_, { isResetShownTimes } = { isResetShownTimes: false }) {
    try {
      const {
        last_appearance: lastAppearance,
        shown_times: shownTimes,
        last_month_filled: lastMonthFilled,
      } = JSON.parse(localStorage.getItem(NPS_SURVEY_LOCAL_STORAGE) || '');
      localStorage.setItem(
        NPS_SURVEY_LOCAL_STORAGE,
        JSON.stringify({
          last_appearance: lastAppearance,
          shown_times: isResetShownTimes ? 0 : Number(shownTimes) + 1,
          last_month_filled: lastMonthFilled || 0,
        })
      );
    } catch {
      setDefaultNpsSurveyLocalStorage();
    }
  },
  updateNpsSurveyLastMonthFilled() {
    try {
      const { last_appearance: lastAppearance, shown_times: shownTimes } = JSON.parse(
        localStorage.getItem(NPS_SURVEY_LOCAL_STORAGE) || ''
      );

      const today = new Date();
      const currentMonth = today.getMonth() + 1;
      localStorage.setItem(
        NPS_SURVEY_LOCAL_STORAGE,
        JSON.stringify({
          last_appearance: lastAppearance,
          shown_times: Number(shownTimes),
          last_month_filled: Number(currentMonth),
        })
      );
    } catch {
      setDefaultNpsSurveyLocalStorage();
    }
  },
};

const mutations = {
  setDigitalSendTransaction(state, transaction) {
    const voucherDiscount =
      transaction.amount.details.voucher_discount == null ? '0' : transaction.amount.details.voucher_discount;
    state.transaction = {
      ...transaction,
      admin_fee:
        parseInt(transaction.amount.details.service_fee, 10) -
        parseInt(transaction.amount.details.subsidies_amount, 10),
      payment_fee: parseInt(transaction.amount.details.payment_fee, 10),
      total: parseInt(transaction.amount.total_payment, 10),
      base_amount: parseInt(transaction.amount.details.transaction, 10),
      subsidi: parseInt(voucherDiscount, 10),
    };
  },

  setDigitalSendTransactionLoading(state, isLoading) {
    state.loading = isLoading;
  },

  setInvoiceDetails(state, invoice) {
    state.invoice = {
      ...invoice,
    };
  },

  setTieringMissionProgress(state, progress) {
    state.tieringMissionProgress = {
      ...progress,
    };
  },
  setTieringMissionEvents(state, events) {
    state.tieringMissionEvents = [...(events ? events.reverse() : [])];
  },

  setDailyCheckinProgress(state, progress) {
    state.dailyCheckinProgress = {
      ...progress,
    };
  },

  setLeaderboardProgress(state, progress) {
    state.leaderboardProgress = {
      ...progress,
    };
  },

  setLeaderboardEventList(state, eventList) {
    state.leaderboardEventList = [...eventList];
  },

  setUserSegments(state, userSegments) {
    state.userSegments = userSegments;
  },

  // pin creation
  setConfigPinCreation(state, configPinCreation) {
    state.configPinCreation = {
      ...configPinCreation,
    };
  },
  setPinCreationGapDays(state, pinCreationGapDays) {
    state.pinCreationGapDays = pinCreationGapDays;
  },
  setPinCreationSkipTimes(state, pinCreationSkipTimes) {
    state.pinCreationSkipTimes = pinCreationSkipTimes;
  },

  // nps survey
  setConfigNpsSurvey(state, configNpsSurvey) {
    state.configNpsSurvey = {
      ...configNpsSurvey,
    };
  },
  setNpsSurveyGapDays(state, npsSurveyGapDays) {
    state.npsSurveyGapDays = npsSurveyGapDays;
  },
  setNpsSurveyShownTimes(state, npsSurveyShownTimes) {
    state.npsSurveyShownTimes = npsSurveyShownTimes;
  },
  setNpsSurveyLastMonthFilled(state, npsSurveyLastMonthFilled) {
    state.npsSurveyLastMonthFilled = npsSurveyLastMonthFilled;
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
