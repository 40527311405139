<template lang="pug">
.page(:style="pageStyle")
  AppHeader#qa-nav-appheader(
    v-if="showAppHeader"
    :class="showTab ? 'no-bottom-border' : ''"
    titleAlign="left"
    @history-back="trackEventClick('back')"
  )
    strong(slot="title") {{ headerTitle }}
  client-only
    LoadingSpinner(v-if="isAppLoading")

  bl-tabs-mv(
    v-if="showTab"
    ref="blTabsMv"
    :tabs="headerTabArray"
    :activeTabName.sync="activeTab"
    :fixedWidthTabs="fixedWidthTabs"
  )
    bl-tab-nav-mv(slot="tab-nav-home" title="Home")
      bl-icon-av(slot="iconTabNav" name="ico_home")
    bl-tab-nav-mv(slot="tab-nav-transaksi" title="TRANSAKSI")
    bl-tab-nav-mv(slot="tab-nav-bantuan" title="BANTUAN")
    bl-tab-nav-mv(slot="tab-nav-profile" title="Profile")
      bl-icon-av(slot="iconTabNav" name="ico_menu")
  FlashAlert.fixed-top

  nuxt

  bl-sheet-mv.page__install-app(:active.sync="downloadAppSheet.shouldShow" @close="closeDownloadApp" auto-height)
    .mh-16.mb-16.mt-24(slot="content")
      .mt-8.mb-24.u-align-center
        BLBPicture(:src="downloadAppSheet.illustration || '/images/lite/merchant-ganti-tampilan.png'" :imgStyle="{ width: '80%' }")
      bl-text-av.mb-8(variation="heading-24" weight="bold") {{ downloadAppSheet.title || 'Download aplikasi Mitra?' }}
      bl-text-av.mb-32 {{ downloadAppSheet.message || 'Biar kamu bebas cobain semua fitur, download dulu aplikasi Mitra Bukalapak.' }}
      bl-button-av.mb-12(variation="primary" :fullwidth="true" @click="clickDownloadApp") Download aplikasi Mitra
      bl-button-av(variation="outline" :fullwidth="true" @click="closeDownloadApp") Nanti aja
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { BlIconAv } from '@bukalapak/bazaar-visual';
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';
import BlTabsMv from '@bukalapak/bazaar-mweb/dist/components/BlTabsMv';
import BlTabNavMv from '@bukalapak/bazaar-mweb/dist/components/BlTabNavMv';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlSheetMv from '@bukalapak/bazaar-mweb/dist/components/BlSheetMv';
import BlEmptyStateMv from '@bukalapak/bazaar-mweb/dist/components/BlEmptyStateMv';
import AppHeader from '~/components/AppHeader';
import FlashAlert from '~/components/FlashAlert';
import LoadingSpinner from '~/components/shared/LoadingSpinner';
import tracker from '~/mixins/tracker';
import PreloaderMixin from '~/mixins/preloader';
import BLBPicture from '~/components/shared/base/BLBPicture';
import { getTrackerPlatform } from '~/utils/platform';

export default {
  name: 'LiteLayout',
  components: {
    BLBPicture,
    BlButtonAv,
    BlIconAv,
    BlTabsMv,
    BlTabNavMv,
    BlTextAv,
    BlSheetMv,
    BlEmptyStateMv,
    AppHeader,
    FlashAlert,
    LoadingSpinner,
  },
  mixins: [tracker, PreloaderMixin],
  data: () => ({
    fixedWidthTabs: ['home', 'profile'],
    downloadCampaignFrom: 'homepage',
    forceShowHeader: false,
    routeToHeaderTitleData: {
      default: 'Mitra Bukalapak',
      agenlite_apps_lite_saldo_activation: 'Aktivasi Saldo',
      top_up_dompet: 'Tambah Saldo',
      agenlite_apps_lite_saldo_activation_TNC: 'Aktivasi Sekarang',
      agenlite_apps_lite_saldo_bind_dana: 'Saldo Mitra Dana',
      bukadana_activation_callback: 'Aktivasi Dana',
      detail_transaction_vp: 'Detail Transaksi',
      detail_transaction_product: 'Detail Transaksi',
      detail_transaction_tix: 'Detail Transaksi',
      transaction_empty: 'Transaksi Saya',
      lite_transactions: 'Transaksi Saya',
      PageLiteMitraType: 'Pilih usahamu',
      loyalty_point_exchange_page: 'Tukar Poin',
      guidance: 'Hubungi Bukalapak',
      guidance_trend_others: 'Bantuan lainnya',
      guidance_category: 'Panduan Mitra Bukalapak',
      guidance_content: 'Panduan Mitra Bukalapak',
      guidance_transaction: 'Pilih transaksi',
      mini_faq_landing: 'Apa kondisi kamu?',
      topup_ondemand_landing: 'Isi Saldo di Tempat',
      topup_ondemand_detail_pengajuan: 'Detail pengajuan',
    },
    routeNameWithTab: ['agenlite_apps_lite_homepage', 'PageLiteMitraProfile', 'PageLiteTransactions'],
    headerTitle: 'Mitra Bukalapak',
    showTab: false,
    activeTab: 'home',
    routeNameOfTabsMap: {
      agenlite_apps_lite_homepage: 'home',
      lite_transactions: 'transaksi',
      lite_transactions_details: 'transaksi',
      guidance: 'bantuan',
      PageLiteMitraProfile: 'profile',
    },
    headerTabArray: ['home', 'transaksi', 'bantuan', 'profile'],
  }),
  head() {
    return {
      title: 'Home',
      titleTemplate: '%s - Mitra Bukalapak',
      meta: [{ hid: 'preloader', name: 'preloader', content: 'disabled' }],
    };
  },
  computed: {
    ...mapGetters(['isAppLoading', 'getInstallSheet', 'downloadAppLink', 'getNeoToggles']),
    ...mapState({
      userAgent: state => state.global.userAgent,
    }),
    tabActions() {
      return {
        home: this.routeTo.bind(this, '/home', 'homepage'),
        transaksi: this.routeTo.bind(this, '/lite/transaksi?with-tab=true', 'log_transaction'),
        bantuan: this.routeTo.bind(this, '/panduan?with-tab=true', 'help'),
        profile: this.routeTo.bind(this, '/lite/mitra/profile?with-tab=true', 'account'),
      };
    },
    downloadAppSheet: {
      get() {
        return this.getInstallSheet;
      },
      set() {
        this.closeDownloadApp();
      },
    },
    isUnderWebview() {
      return getTrackerPlatform(this.userAgent) !== 'sentinel_pwa';
    },
    showAppHeader() {
      if (this.$route.meta?.hideAppHeader) return false;
      return this.isUnderWebview || this.forceShowHeader || (!this.isUnderWebview && !this.showTab);
    },
  },
  watch: {
    $route() {
      this.changeHeaderLayoutContent();
      this.forceShowHeader = false;
    },
    activeTab(val, _prev) {
      // TODO: prevent tabActions when val already equal to route AND _prev is default data value (home)
      this.tabActions[val]();
    },
  },
  created() {
    this.changeHeaderLayoutContent();
  },
  mounted() {
    this.initHeaderToggleListener();
  },
  methods: {
    changeHeaderLayoutContent() {
      if (process.client) this.$nativeBridge.setTopBar(JSON.stringify({ hidden: true }));
      const { name, query } = this.$route;
      if (query['with-tab']) {
        const isCurrentRouteTabRuleExist = this.routeNameWithTab.indexOf(name) === 1;
        if (!isCurrentRouteTabRuleExist) this.routeNameWithTab.push(name);
      }
      this.headerTitle = this.routeToHeaderTitleData[name] || this.routeToHeaderTitleData.default;
      this.showTab = this.routeNameWithTab.includes(name);
      if (this.showTab) {
        this.activeTab = this.routeNameOfTabsMap[name] || 'home';
      }
    },
    initHeaderToggleListener() {
      this.$nuxt.$on('headerToggled', flag => {
        this.forceShowHeader = flag;
      });
    },
    routeTo(path, trackerEvn) {
      this.trackNavbarTabClick(`navbar_tab_${trackerEvn}`);
      this.$router.replace(path);
    },
    openDownloadApp() {
      this.$store.dispatch('showInstallSheet', {
        title: 'Mau beli stok warung?',
        message: 'Kamu bisa belanja stok warung dengan mudah dan murah, diantar langsung ke warung kamu.',
      });
    },
    clickDownloadApp() {
      this.trackEventClick('navigate_to_download');
      this.$location.go(this.downloadAppLink, {
        campaign: `tombol-unduh-existing-${this.downloadCampaignFrom}`,
      });
    },
    closeDownloadApp() {
      this.$store.dispatch('hideInstallSheet');
      this.$refs.blTabsMv.activeTab = this.activeTab; // hack tabMv to override it's activeTab Data
    },
  },
};
</script>
<style lang="scss">
@import '~@bukalapak/bazaar-token/dist/tokens.scss';

.bl-sheet,
.bl-sheet__box,
.bl-sheet__nav-bar {
  max-width: 540px;
  margin: auto;
}

.no-bottom-border > .c-header {
  border-bottom: 0 !important;

  .c-header__title {
    text-align: left;
    margin-left: 10vh;
  }
}
.page {
  position: relative;
  max-width: 540px;
  margin: auto;
  &-header {
    position: fixed;
    left: 0;
    right: 0;
    z-index: $z-index-header;
    background-color: $system-white;
  }
  &-content {
    padding-top: 106px;
  }
  .bl-tab__nav {
    border-bottom: 1px solid $gray-30;
    padding: 0 $space-x-small;
    &--auto {
      overflow-x: hidden;
      &:after {
        padding: 0;
      }
      .bl-tab__action {
        flex-grow: 1;
        padding: 12px $space-xx-small;
      }
    }
  }
  ul {
    li {
      margin: 0;
    }
  }

  &__install-app {
    .bl-empty-state {
      height: 60vh;
    }
  }
}
</style>
