import AgenliteAPIClient from '~/api';

const initialState = {
  toggles: {},
  segmentations: {},
  configs: {},
};

const storeGetters = {
  getNeoToggles: ({ toggles }) => toggles,
  getNeoSegmentations: ({ segmentations }) => segmentations,
  getToggleConfig: state => key => state.configs[key]?.data || {},
};

const actions = {
  initPwaToggles({ commit, dispatch }, force) {
    if (force) commit('clearNeoToggles');
    return dispatch('fetchNeoToggles', [
      'agenlite-pwa-loyalty-kejar-poin-entry',
      'agenlite/toggle/data_plan_menu',
      'agenlite/toggle/multifinance_menu',
      'agenlite/toggle/phone_credit_menu',
      'agenlite/toggle/prepaid_electricity_menu',
      'agenlite/toggle/tiket_kereta_menu',
      'agenlite/toggle/topup_bukadana_customer',
      'agenlite/toggle/virtual_product_onboarding',
      'agenlite/toggle/virtual_product_voucher_pwa',
      'agent/toggle/agent_loyalty_point',
      'agent/toggle/agent_loyalty_point_grant_bayar_bukalapak',
      'agent/toggle/dana_feature',
      'agent/toggle/general_trade_voucher',
      'agent/toggle/general_trade_voucher_suggestion',
      'agent/toggle/whitelist_all',
      'o2o-mitra-pwa-redeem-coupons',
      'o2o-mitra-pwa-topup-ondemand',
      'o2o-mitra-pwa-topup-ondemand-cancel',
      'o2o-dgx/bookkeeping_automatic_entry_feature',
      'mitra-loyalty-tiering',
      'o2o-dgx/searchable-categories',
      'o2o-saas/promotion_media_vp_catalog_feature',
      'o2o-mitra-referral-onboarding-enabled',
      'o2o-dgx/bookkeeping-coachmark',
      'o2o-dgx/prefill-form-debt-notes',
      'o2o-saas/promotion_media_poster_feature',
      'o2o-saas/promotion_media_catalog_feature',
      'o2o-saas/promotion_media_catalog_without_image_feature',
      'o2o-saas/promotion_media_banner_feature',
      'o2o-saas/promotion_media_business_card_feature',
      'o2o-saas/payment_request_feature',
      'o2o-saas/payment_request_share',
      'o2o-saas/payment_request_onboarding',
      'o2o-saas/bookkeeping_unpaid_transaction',
      'o2o-mitra/livechat-phase3',
      'mitra-mini-faq-enabled',
      'o2o-mitra/referral-reward-performance',
      'o2o-saas/my_debt_note_feature',
      'o2o-saas/bookkeeping_receipt_feature',
      'o2o-saas/debtnote_sorting',
      'o2o-saas/bookkeeping_report',
      'o2o-mitra/referral-claim-benefit-platinum',
      'o2o-act/dipromosiin-vindes',
      'o2o-act/dipromosiin-vindes-check',
      'o2o-saas/settlement_calculations',
      'o2o-saas/bookkeeping_debt_due_date',
      'o2o-activation/mitra-activation-referral-poster',
      'o2o-pricing/superwarung-landing-page',
      'o2o-rtn/loyalty_reward_categorization',
      'o2o-rtn/opt_in_loyalty',
      'o2o-rtn/mitra_mission',
      'o2o-rtn/shorten-expiration-point',
      'o2o-rtn/expiry_point_announcement',
      'o2o-rtn/new_uptier_target',
      'o2o-act/new-referral-dashboard',
      'o2o-act/new-referral-dashboard-detail-referee',
      'o2o-rtn/sebar_poster_improvement',
      'o2ovpd/toggle/mitra/mweb_electricity_postpaid_feature',
      'o2ovpd/toggle/mitra/mweb_bpjs_kesehatan_feature',
      'o2ovpd/toggle/mitra/mweb_multifinance_feature',
      'o2ovpd/toggle/mitra/mweb_pdam_feature',
      'o2ovpe/toggle/mitra/mweb_economic_prepaid_phone',
      'o2ovpe/toggle/mitra/mweb_selling_package',
      'o2ovpe/toggle/mitra/mweb_pulsa_multiple_source',
      'oom/new-vp-transaction-detail',
      'oom/topup-digital-webview',
      'mtr/sunset_bukumitra_phase_1',
      'mitra-self-service-change-phone-number',
      'mtr/disable_topup_bonus_agentcoordinator',
      'mtr/sa_topup_to_topup_digital',
      'oom/kontak-pelanggan-change-contact-detail',
      'mitra-buka20-wallet-enabled',
      'o2ovpe/toggle/mitra/wallet_open_amount',
      'mtr/topup-sa-trx-history-buka20-enabled',
      'payment/digital-send/auto-save-contact-transaction-detail',
      'payment/mitra-lakupandai-leaderboard/entrypoint-transaction-detail',
      'payment/mitra-lakupandai-tiering-mission/entrypoint-transaction-detail',
      'payment/mitra-lakupandai-daily-checkin/entrypoint-transaction-detail',
      'o2o-mtr/disable_payment_link',
      'o2o-saas/share_debt_mutation',
      'o2o-saas/multiple_debt_notes',
      'o2o-saas/product_item_feature',
      'o2o-vpe/ewallet-trx-announcement',
      'mitra-standardize-kyc-enabled',
    ]);
  },
  // Only call this action if you think you dont need the toggle initiated in homepage
  async fetchNeoToggles({ dispatch, commit, getters }, toggleIds) {
    try {
      const uninitiatedToggleIds = [];
      toggleIds.forEach(toggle => {
        if (!Object.keys(getters.getNeoToggles).includes(toggle)) {
          uninitiatedToggleIds.push(toggle);
        }
      });
      if (uninitiatedToggleIds.length <= 0) return;
      const response = await AgenliteAPIClient.fetchNeoToggles(uninitiatedToggleIds);
      const mappedToggles = {};
      const mappedSegmentation = {};
      response.data.forEach(toggle => {
        mappedToggles[toggle.id] = toggle.active;
        mappedSegmentation[toggle.id] = toggle.segmentation;
      });
      commit('setNeoToggles', { toggles: mappedToggles, segmentations: mappedSegmentation });
    } catch (error) {
      dispatch('catchError', { message: error, hideAppLoading: true, noFlashAlert: true });
    }
  },
  async fetchNeoConfigs({ commit, dispatch }, configKeys) {
    try {
      const response = await AgenliteAPIClient.fetchNeoConfigs(configKeys);
      const configs = {};
      response.data.forEach(config => {
        configs[config.id] = config;
      });
      commit('setNeoConfigs', configs);
      return response.data;
    } catch (error) {
      dispatch('catchError', { message: error, hideAppLoading: true, noFlashAlert: true });
      throw error;
    }
  },
};

const mutations = {
  setNeoToggles(state, { toggles, segmentations = {} }) {
    state.toggles = { ...state.toggles, ...toggles };
    state.segmentations = { ...state.segmentations, ...segmentations };
  },
  clearNeoToggles(state) {
    state.toggles = {};
    state.segmentations = {};
  },
  setNeoConfigs(state, configs) {
    state.configs = { ...state.configs, ...configs };
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: storeGetters,
  actions,
  mutations,
};
