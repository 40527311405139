import r from '~/utils/render-utils';
import { redirect } from '~/plugins/LocationPlugin';

// internal Lite Landing
import PageLiteLanding from '~/pages/System/Landing';

// new Lite Homepage
const Homepage = () => import(/* webpackChunkName: 'homepage-layout' */ '~/pages/Lite/Homepage');

// new Saldo Activation
const SaldoActivation = () => import(/* webpackChunkName: 'saldo-activation' */ '~/pages/Lite/SaldoActivation');

// new Saldo MIX Dana TNC
const SaldoDanaTNC = () => import(/* webpackChunkName: 'saldo-activation' */ '~/pages/Lite/SaldoDanaTNC');
const BindDanaPage = () => import(/* webpackChunkName: 'saldo-activation' */ '~/pages/Lite/BindDana');

// new Profile Page
const PageLiteMitraProfile = () => import(/* webpackChunkName: 'lite-profile'  */ '~/pages/Lite/Mitra/Profile');
const PageLiteMitraType = () => import(/* webpackChunkName: 'lite-profile'  */ '~/pages/Lite/Mitra/Type');

// Transactions
const PageTransactions = () => import(/* webpackChunkName: 'lite-transactions' */ '~/pages/Lite/Transaksi');
const PageTransactionDetails = () => import(/* webpackChunkName: 'lite-transactions' */ '~/pages/Lite/Transaksi/Details');

const lite = [
  {
    path: '/home',
    component: r(Homepage),
    name: 'agenlite_apps_lite_homepage',
  },
  {
    path: '/aktivasi-saldo',
    component: r(SaldoActivation),
    name: 'agenlite_apps_lite_saldo_activation',
  },
  {
    path: '/aktivasi-saldo/terms',
    component: r(SaldoDanaTNC),
    name: 'agenlite_apps_lite_saldo_activation_TNC',
  },
  {
    path: '/aktivasi-saldo/dana',
    component: r(BindDanaPage),
    name: 'agenlite_apps_lite_saldo_bind_dana',
  },
  {
    path: '/lite',
    component: PageLiteLanding,
    name: 'lite_landing',
  },
  {
    path: '/lite/mitra/profile',
    component: r(PageLiteMitraProfile),
    name: 'PageLiteMitraProfile',
  },
  {
    path: '/lite/mitra/type',
    component: r(PageLiteMitraType),
    name: 'PageLiteMitraType',
  },
  {
    path: '/lite/transaksi',
    component: r(PageTransactions),
    name: 'lite_transactions',
    children: [
      {
        path: ':product/:id',
        props: true,
        component: r(PageTransactionDetails),
        name: 'lite_transactions_details'
      },
    ],
  },
  {
    path: '/transaksi',
    beforeEnter(_to, _from, next) {
      redirect('/lite/transaksi?with-tab=true');
      next(false);
    },
  },
  {
    path: '/list-transaksi',
    beforeEnter(_to, _from, next) {
      redirect('/lite/transaksi?with-tab=true');
      next(false);
    },
  },
];

export default lite;
