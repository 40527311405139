import { SharedCustomerProduct } from '@bukalapak/openapi-schema/vptrader/product/v1/public/product-domain';
import { ALLOWLIST_FEATURE } from '~/utils/subscription';
import VPNonTraderAPIClient from '~/api/vpnontrader';
import AgenliteAPIClient from '~/api/index';

interface CheckoutList {
  [dealId: string]: string;
}

interface FeatureBenefit {
  title: string;
  subtitle: string;
  detail_link: string;
  detail_text: string;
}

interface Feature {
  feature: string;
  image_url: string;
  subtitle: string;
  benefits: FeatureBenefit[];
}

interface SubscriptionDetail extends Feature {
  skus: any[];
  price: number;
  name: string;
}

type InitialState = {
  checkoutList: CheckoutList;
  featureList: Feature[];
  subscriptionDetail: SubscriptionDetail;
  recoName: string;
  isBuka20Whitelisted?: boolean;
  buka20MitraSubscriptionProducts: SharedCustomerProduct[];

  loading: {
    checkoutFeatureList: boolean;
    subscriptionDetail: boolean;
  };
};

const initialState: InitialState = {
  checkoutList: {},
  featureList: [],
  subscriptionDetail: {
    feature: '',
    image_url: '',
    subtitle: '',
    benefits: [],
    skus: [],
    price: 0,
    name: '',
  },
  recoName: '',
  isBuka20Whitelisted: undefined,
  buka20MitraSubscriptionProducts: [],

  loading: {
    checkoutFeatureList: true,
    subscriptionDetail: true,
  },
};

const getters = {
  getSubscriptionDetail(state: InitialState) {
    return state.subscriptionDetail;
  },
  getSubscriptionDetailRecoName(state: InitialState) {
    return state.recoName;
  },
  getSubscriptionDealIdByFeature: (state: InitialState) => (feature: string) => {
    if (state.isBuka20Whitelisted) {
      return state.buka20MitraSubscriptionProducts.find(p => p.code === feature)?.id || '';
    }
    return Object.keys(state.checkoutList).find(key => state.checkoutList[key] === feature) || '';
  },
  getSubscriptionLoading(state: InitialState) {
    return state.loading;
  },
  getIsBuka20Whitelisted(state: InitialState) {
    return state.isBuka20Whitelisted;
  },
  getBuka20MitraSubscriptionProducts(state: InitialState) {
    return state.buka20MitraSubscriptionProducts;
  },
};

const actions = {
  async retrieveCheckoutFeatureList({ commit, dispatch }) {
    let response;

    commit('setLoadingState', { name: 'checkoutFeatureList', value: true });
    try {
      response = await dispatch('fetchNeoConfigs', ['subscription-feature-list', 'subscription-checkout-list']);
      const featureList = response?.[0]?.data?.data;
      const checkoutList = response?.[1]?.data;

      commit('setCheckoutList', checkoutList);
      commit('setFeatureList', featureList);
    } catch (error) {
      const message = 'Gagal Mendapatkan data.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      commit('setLoadingState', { name: 'checkoutFeatureList', value: false });
    }
    return { response };
  },
  async retrieveSubscriptionDetail({ commit, dispatch }, dealId: number) {
    const whitelistRes = await (AgenliteAPIClient as any).getWallet20WhitelistStatus('mitra-subscription');
    const isBuka20Whitelisted = whitelistRes?.data?.whitelisted;

    commit('setIsBuka20Whitelisted', isBuka20Whitelisted);

    let response;
    if (isBuka20Whitelisted) {
      response = await dispatch('retrieveBuka20SubscriptionDetail', dealId);
    } else {
      response = await dispatch('retrieveLegacySubscriptionDetail', dealId);
    }

    return { response };
  },
  async retrieveBuka20SubscriptionDetail({ state, commit, dispatch }, dealId: number) {
    let response;

    commit('setLoadingState', { name: 'subscriptionDetail', value: true });
    try {
      response = await VPNonTraderAPIClient.getCustomerProducts({ product_type: 'mitra-subscription' });
      commit('setBuka20MitraSubscriptionProducts', response?.customer_products);

      const product = response?.customer_products.find(p => p.id === dealId.toString());
      const featureDetail: Feature = state.featureList.find(f => f.feature === product.code);

      // Convert to legacy data structure. Only used data is filled.
      const sku: any = {
        id: product.id,
        name: product.name,
        price: +product.price,
      };

      const discount = +product.discount;
      if (discount) {
        sku.discount = {
          discounted_price: +product.price,
          original_price: +product.base_price,
        };
      }

      const subscriptionDetail: SubscriptionDetail = {
        ...featureDetail,
        skus: [sku],
        price: +product.price,
        name: product.name,
      };

      commit('setSubscriptionDetail', subscriptionDetail);
    } catch (error) {
      const message = 'Gagal Mendapatkan data.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      commit('setLoadingState', { name: 'subscriptionDetail', value: false });
    }
    return { response };
  },
  async retrieveLegacySubscriptionDetail({ commit, dispatch, state }, dealId: number) {
    let response;

    commit('setLoadingState', { name: 'subscriptionDetail', value: true });
    try {
      response = await dispatch('fetchDealsDetail', dealId);
      const dealsDetail = response?.data;

      const feature: string = state.checkoutList[dealId];
      const featureDetail: Feature = state.featureList.find(f => f.feature === feature);

      const subscriptionDetail: SubscriptionDetail = {
        ...featureDetail,
        skus: dealsDetail?.skus,
        price: dealsDetail?.price,
        name: dealsDetail?.name,
      };

      commit('setSubscriptionDetail', subscriptionDetail);
    } catch (error) {
      const message = 'Gagal Mendapatkan data.';
      dispatch('catchError', { error, message, noFlashAlert: false });
    } finally {
      commit('setLoadingState', { name: 'subscriptionDetail', value: false });
    }
    return { response };
  },
  async checkSubscriptionDetailRecommendation({ state, commit, dispatch }) {
    switch (state.subscriptionDetail?.feature) {
      case ALLOWLIST_FEATURE.REFUND_STANDALONE: {
        const response = await dispatch('retrieveAllowlistMeFeature', { feature: ALLOWLIST_FEATURE.JAGOAN_VIRTUAL });
        const isActive = response?.data?.value === 'allowed';

        if (isActive) {
          commit('setRecoName', 'jagoan_downsell');
        }
        break;
      }
      case ALLOWLIST_FEATURE.JAGOAN_VIRTUAL: {
        const response = await dispatch('retrieveAllowlistMeFeature', { feature: ALLOWLIST_FEATURE.REFUND_STANDALONE });
        const isActive = response?.data?.value === 'allowed';

        if (isActive) {
          commit('setRecoName', 'jagoan_upsell');
        }
        break;
      }
    }
  },
  async createBuka20MitraSubscriptionTransaction({ dispatch }, payload) {
    let response;
    try {
      dispatch('showAppLoading');
      response = await VPNonTraderAPIClient.createCustomerTransaction(payload);
    } catch (error) {
      const message = error?.errors?.length > 0 ? error.errors[0].message : 'Terjadi kesalahan, silakan coba lagi.';
      dispatch('showFlashAlert', { message, type: 'warning' });
    } finally {
      dispatch('hideAppLoading');
    }
    return response;
  },
};

const mutations = {
  setCheckoutList(state, value: CheckoutList) {
    state.checkoutList = value;
  },
  setFeatureList(state, value: Feature[]) {
    state.featureList = value;
  },
  setSubscriptionDetail(state, value: SubscriptionDetail) {
    state.subscriptionDetail = value;
  },
  setRecoName(state, value: string) {
    state.recoName = value;
  },
  setLoadingState(state, payload: { name: 'checkoutFeatureList' | 'subscriptionDetail'; value: boolean }) {
    state.loading[payload.name] = payload.value;
  },
  setIsBuka20Whitelisted(state, value: boolean) {
    state.isBuka20Whitelisted = value;
  },
  setBuka20MitraSubscriptionProducts(state, value: SharedCustomerProduct[]) {
    state.buka20MitraSubscriptionProducts = value;
  },
};

export default {
  state: () => ({ ...initialState }),
  mutations,
  getters,
  actions,
};
