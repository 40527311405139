import AgenliteAPIClient from '~/api';


const initialState = {
  kycBasic: {},
  standardizeKYCBasic: {},
};

const getters = {
  getKYCBasic(state) {
    return state.kycBasic;
  },
  isKYCVerified(state) {
    return state.kycBasic?.state === 'verified';
  },
  isStandardizeKYCBasicVerified(state) {
    return state.standardizeKYCBasic?.state?.basic === 'verified';
  },
};

const mutations = {
  setKYCBasic(state, kycBasic) {
    state.kycBasic = kycBasic;
  },
  setStandardizeKYC(state, standardizeKYC) {
    state.standardizeKYCBasic = standardizeKYC;
  },
};

const actions = {
  async fetchMitraKYC({ commit, dispatch }) {
    let response = null;
    // eslint-disable-next-line no-console
    console.log('Fetching Mitra KYC data...');
    try {
      response = await AgenliteAPIClient.getMitraKYC();
      commit('setKYCBasic', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data KYC basic.';
      dispatch('catchError', { error, message, noFlashAlert: true }, { root: true });
      throw error;
    }
    return response?.data;
  },

  async fetchStandardizeKYC({ commit, dispatch }) {
    let response = null;
    // eslint-disable-next-line no-console
    console.log('Fetching Standardize KYC data...');
    try {
      response = await AgenliteAPIClient.getStandardizeKYC();
      commit('setStandardizeKYC', response.data);
    } catch (error) {
      const message = 'Gagal mendapatkan data KYC basic.';
      dispatch('catchError', { error, message, noFlashAlert: true }, { root: true });
      throw error;
    }
    return response?.data;
  },
};

export default {
  state: () => ({ ...initialState }),
  getters,
  actions,
  mutations,
};
