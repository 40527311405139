const defaultState = {
  isActive: false,
  ack: false,
  postponedUntil: null,
  additionalTrigger: '#<trigger>',
  action: null,
  skipCounter: 0,
  maxSkip: 3,
};

const initialState = {
  onboardingTooltipState: {
    // state for tooltip style onboarding
    // list here for each feature and step
    topup_dana_cust: {
      1: {
        ...defaultState,
        isActive: true,
        activeRoute: 'agenlite_home',
        action: 'goto:/topup-dana-customer',
        content: '<p>Sekarang, kamu bisa melakukan Top Up DANA untuk pelanggan kamu loh.</p>',
      },
    },
  },
};

const moduleGetters = {
  getOnboardingTooltipState(state) {
    return state.onboardingTooltipState;
  },
};

const actions = {
  toggleOnboardingTooltipStep({
    commit,
  }, opt) {
    commit('toggleOnboardingTooltipStep', opt);
  },
  postponeOnboardingTooltipStep({
    commit,
  }, opt) {
    commit('postponeOnboardingTooltipStep', opt);
  },
};

const mutations = {
  toggleOnboardingTooltipStep(state, {
    onbKey,
    step,
    isActive,
    ack = false,
  }) {
    state.onboardingTooltipState[onbKey][step].isActive = isActive;
    state.onboardingTooltipState[onbKey][step].ack = ack;
  },
  postponeOnboardingTooltipStep(state, {
    onbKey,
    step,
  }) {
    const {
      skipCounter,
      maxSkip,
    } = state.onboardingTooltipState[onbKey][step];
    let isActive = false;
    let postponedUntil = null;

    if (skipCounter + 1 < maxSkip) {
      const POSTPONE_PERIOD = process.env.NODE_ENV === 'production' ? 24 * 60 : 1; // in minutes
      const multiplier = {
        0: 5,
        1: 9,
      };
      isActive = true;
      postponedUntil = (new Date()).getTime() + POSTPONE_PERIOD * multiplier[skipCounter] * 60 * 1000;
    }

    state.onboardingTooltipState[onbKey][step] = {
      ...state.onboardingTooltipState[onbKey][step],
      isActive,
      postponedUntil,
      skipCounter: skipCounter + 1,
    };

    if (state.onboardingTooltipState[onbKey][step + 1] && !state.onboardingTooltipState[onbKey][step + 1].ack) state.onboardingTooltipState[onbKey][step + 1].isActive = true;
  },
  resetState() {
    // state.onboardingTooltipState = JSON.parse(JSON.stringify(initialState.onboardingTooltipState));
  },
};

export default {
  state: () => ({ ...initialState }),
  getters: moduleGetters,
  actions,
  mutations,
};
