import SharedConfig from '~/plugins/SharedConfig';
import API from '~/api';
import { promiseAlmost } from '~/utils/promise-utils';

const danaUserWhitelist = [
  // Bukalapak
  '6281958647934', // walldan
  '6281958647940', // walldan1
  '6281958647939', // walldan2
  '6281958647941', // walldan3
  '6281958647942', // walldan4
  '6281320634860', // PM Squad Dana (Fahmi)
  '6281293942962', // PM Squad O2O WallE (Riana)
  // DANA
  '628176693716',
  '628176693720',
  '6287824655095',
  '6281907164271',
  '6281907164275',
  '628176693751',
  // Additional DANAtest
  '6281298765432', // danatest5
  '6281267543212', // danatest378
  '628998734670', // yadana3
  '6287878069743',
  '6287878069744',
  '6287878069745',
  '6287878069735',
  '6287878069736',
  '6285797513593',
  '6285885439499',
];

const mapNeoToSharedConfig = (neoToggles, sharedConfig) => {
  const isDanaUserWhitelist = danaUserWhitelist.includes(sharedConfig?.user?.phone);
  const oneDay = 60 * 60 * 24 * 1000;
  const danaFeature =
    neoToggles['agent/toggle/dana_feature'] &&
    sharedConfig?.user?.id &&
    new Date() - new Date(sharedConfig?.user?.['joined_at']) >= oneDay;
  return {
    agentLoyaltyPoint: neoToggles['agent/toggle/agent_loyalty_point'],
    bayarBukalapakLoyaltyPointGrant: neoToggles['agent/toggle/agent_loyalty_point_grant_bayar_bukalapak'],
    danaFeature: danaFeature ?? isDanaUserWhitelist,
    dataPlanMenu: neoToggles['agenlite/toggle/data_plan_menu'],
    generalTradeVoucher: neoToggles['agent/toggle/general_trade_voucher'],
    generalTradeVoucherSuggestion: neoToggles['agent/toggle/general_trade_voucher_suggestion'],
    general_trade_availability: false, // Confirmed by O2O-GRC to make this toggle hardcoded
    promotionMediaPoster: neoToggles['o2o-saas/promotion_media_poster_feature'],
    promotionMediaCatalog: neoToggles['o2o-saas/promotion_media_catalog_feature'],
    promotionMediaCatalogWithoutImage: neoToggles['o2o-saas/promotion_media_catalog_without_image_feature'],
    promotionMediaBanner: neoToggles['o2o-saas/promotion_media_banner_feature'],
    promotionMediaBusinessCard: neoToggles['o2o-saas/promotion_media_business_card_feature'],
    paymentLinkFeature: neoToggles['o2o-saas/payment_request_feature'],
    paymentLinkShare: neoToggles['o2o-saas/payment_request_share'],
    paymentLinkOnboarding: neoToggles['o2o-saas/payment_request_onboarding'],
    multifinanceMenu: neoToggles['agenlite/toggle/multifinance_menu'],
    onboardingVP: neoToggles.onboardingVP ?? neoToggles['agenlite/toggle/virtual_product_onboarding'],
    phoneCreditMenu: neoToggles['agenlite/toggle/phone_credit_menu'],
    prepaidElectricityMenu: neoToggles['agenlite/toggle/prepaid_electricity_menu'],
    tiketKeretaMenu: neoToggles['agenlite/toggle/tiket_kereta_menu'],
    topupBukadanaCustomerMenu: neoToggles['agenlite/toggle/topup_bukadana_customer'],
    virtualProductVoucherPwa: neoToggles['agenlite/toggle/virtual_product_voucher_pwa'],
    whitelistAll: neoToggles['agent/toggle/whitelist_all'],
    bookkeepingAutomaticEntry: neoToggles['o2o-dgx/bookkeeping_automatic_entry_feature'],
    bookkeepingSearchableCategories: neoToggles['o2o-dgx/searchable-categories'],
    promotionMediaVpCatalog: neoToggles['o2o-saas/promotion_media_vp_catalog_feature'],
    bookkeepingCoachmark: neoToggles['o2o-dgx/bookkeeping-coachmark'],
    bookkeepingUnpaidTransaction: neoToggles['o2o-saas/bookkeeping_unpaid_transaction'],
    prefillFormDebtNotes: neoToggles['o2o-dgx/prefill-form-debt-notes'],
    myDebtNote: neoToggles['o2o-saas/my_debt_note_feature'],
    bookkeepingReceipt: neoToggles['o2o-saas/bookkeeping_receipt_feature'],
    debtnoteSorting: neoToggles['o2o-saas/debtnote_sorting'],
    dipromosiinVindes: neoToggles['o2o-act/dipromosiin-vindes'],
    dipromosiinVindesCheck: neoToggles['o2o-act/dipromosiin-vindes-check'],
    bookkeepingReport: neoToggles['o2o-saas/bookkeeping_report'],
    bookkeepingDebtDueDate: neoToggles['o2o-saas/bookkeeping_debt_due_date'],
    referralPoster: neoToggles['o2o-activation/mitra-activation-referral-poster'],
    optInLoyaltyProgram: neoToggles['o2o-rtn/opt_in_loyalty'],
    mitraMission: neoToggles['o2o-rtn/mitra_mission'],
    kejarPoinEntryPoint: neoToggles['agenlite-pwa-loyalty-kejar-poin-entry'],
    shortenExpirationPoint: neoToggles['o2o-rtn/shorten-expiration-point'],
    expiryPointAnnouncement: neoToggles['o2o-rtn/expiry_point_announcement'],
    newUptierTarget: neoToggles['o2o-rtn/new_uptier_target'],
    newReferralDashboard: neoToggles['o2o-act/new-referral-dashboard'],
    newReferralDashboardDetailReferee: neoToggles['o2o-act/new-referral-dashboard-detail-referee'],
    sebarPosterImprovement: neoToggles['o2o-rtn/sebar_poster_improvement'],
    superWarungLandingPage: neoToggles['o2o-pricing/superwarung-landing-page'],
    postpaidFeatureElectricity: neoToggles['o2ovpd/toggle/mitra/mweb_electricity_postpaid_feature'],
    postpaidFeatureBPJS: neoToggles['o2ovpd/toggle/mitra/mweb_bpjs_kesehatan_feature'],
    postpaidFeatureMultifinance: neoToggles['o2ovpd/toggle/mitra/mweb_multifinance_feature'],
    postpaidFeaturePDAM: neoToggles['o2ovpd/toggle/mitra/mweb_pdam_feature'],
    topupDigital: neoToggles['oom/topup-digital-webview'],
    newVPTrxDetail: neoToggles['oom/new-vp-transaction-detail'],
    shareDebtMutation: neoToggles['o2o-saas/share_debt_mutation'],
    disablePaymentLink: neoToggles['o2o-mtr/disable_payment_link'],
    sunsetBukuMitraPhase1: neoToggles['mtr/sunset_bukumitra_phase_1'],
    selfUpdatePhoneNumber: neoToggles['mitra-self-service-change-phone-number'],
    disableTopupBonus: neoToggles['mtr/disable_topup_bonus_agentcoordinator'],
    topupDigitalforSA: neoToggles['mtr/sa_topup_to_topup_digital'],
    kontakPelangganChangeContactDetail: neoToggles['oom/kontak-pelanggan-change-contact-detail'],
    wallet20: neoToggles['mitra-buka20-wallet-enabled'],
    topupSATrxHistoryBuka20: neoToggles['mtr/topup-sa-trx-history-buka20-enabled'],
    multipleDebtNotes: neoToggles['o2o-saas/multiple_debt_notes'],
    productItems: neoToggles['o2o-saas/product_item_feature'],
    ewalletTrxAnnouncement: neoToggles['o2o-vpe/ewallet-trx-announcement'],
    standardizeKYC: neoToggles['mitra-standardize-kyc-enabled'],
  };
};

const initialState = {
  application: {
    api_url: process.env.API_HOST,
    api_v4_url: process.env.API_V4_HOST,
    env: process.env.NODE_ENV,
  },
  userAgent: '',
  referrer: undefined,
  ssr: false,
  sharedConfig: {
    toggle: {},
    user: {
      identity: null,
    },
  },
  downloadAppLink: 'https://app.adjust.com/hi0izwq',
};

const actions = {
  clearIdentity({ commit }) {
    commit('removeIdentity');
  },
  assignIdentity({ commit }) {
    commit('setIdentity');
  },
  initV4Token(useAgentId = false) {
    return API.fetchTokenV4(useAgentId);
  },
  async initSharedConfig({ commit, dispatch, getters }, { originalUrl, force = false }) {
    const sharedConfigBypassRoutes = ['/logout', '/authorize', '/sales/authorize', '/kurir/authorize'];
    const pathName = process.server ? originalUrl : window.location.pathname;
    if (sharedConfigBypassRoutes.includes(pathName) && !force) return;
    const [sharedConfig] = await promiseAlmost([SharedConfig.fetch(force), dispatch('initPwaToggles', force)]);

    commit('setSharedConfig', {
      ...sharedConfig,
      toggle: mapNeoToSharedConfig(getters.getNeoToggles, sharedConfig),
    });
  },
  async refetchSharedConfig({ dispatch }) {
    await dispatch('initSharedConfig', { force: true });
  },
  setToggleConfig({ commit }, toggleConfig) {
    commit('setToggleConfig', toggleConfig);
  },
  clearReportTimer() {
    clearTimeout(window.premetricsTimer);
    delete window.premetricsTimer;
    if (!window.reported) console.warn('Client loaded on safe threshold time.');
    delete window.reported;
  },
};

const getters = {
  globalApplication: state => state.application,
  globalUser: state => state.sharedConfig.user,
  sharedConfig: state => state.sharedConfig,
  toggleConfig: state => state.sharedConfig.toggle,
  getUserId: state => state.sharedConfig.user.id,
  getUserId36: state => {
    if (
      state.sharedConfig.user.id !== null &&
      state.sharedConfig.user.id !== '' &&
      typeof state.sharedConfig.user.id !== 'undefined'
    ) {
      return Number(state.sharedConfig.user.id).toString(36);
    }
    return '0'.toString(36);
  },
  getUserPhone: state =>
    state.sharedConfig.user && state.sharedConfig.user.phone ? state.sharedConfig.user.phone.replace(/^62/, '0') : '',
  isUserPhoneConfirmed: state => state.sharedConfig.user && state.sharedConfig.user.phone_confirmed,
  getUsername: state => state.sharedConfig.user.username,
  downloadAppLink: state => state.downloadAppLink,
};

const mutations = {
  removeIdentity(state) {
    state.sharedConfig.user = {};
    state.sharedConfig.user = {
      identity: API.credentials.identity,
    };
  },
  setIdentity(state) {
    if (!state.sharedConfig.user.identity) state.sharedConfig.user.identity = API.credentials.identity;
  },
  setUserAgent(state, userAgent) {
    state.userAgent = userAgent;
  },
  setSSR(state, ssrState) {
    state.ssr = ssrState;
  },
  setReferrer(state, referrer) {
    state.referrer = referrer;
  },
  setSharedConfig(state, sharedConfig) {
    state.sharedConfig = {
      ...sharedConfig,
      user: {
        ...sharedConfig.user,
        identity: sharedConfig.user?.identity || state.sharedConfig.user.identity,
      },
    };
  },
  setToggleConfig(state, toggleConfig) {
    state.sharedConfig.toggle = {
      ...state.sharedConfig.toggle,
      ...toggleConfig,
    };
  },
  resetState(state) {
    state.sharedConfig.user = {
      ...initialState.sharedConfig.user,
    };
  },
  yieldAggregate(state, bundle) {
    const module = bundle.global;
    if (!module) return;

    state.sharedConfig.toggle = {
      ...state.sharedConfig.toggle,
      ...module.toggleConfig,
    };
  },
};

export default {
  state: () => ({
    ...initialState,
  }),
  getters,
  actions,
  mutations,
};
