import { mapGetters } from 'vuex';
import { sha256 } from 'js-sha256';
import { getCurrentTime } from '~/server/helpers/date-helper';

// Modules
import digitalSendTracker from '~/mixins/tracker/digital-send';
import loginRegister from '~/mixins/tracker/login-register';
import loyaltyPointTracker from '~/mixins/tracker/loyalty-point';
import miniFAQ from '~/mixins/tracker/miniFAQ';
import profitReports from '~/mixins/tracker/profit-reports';
import topupTracker from '~/mixins/tracker/topup';
import topUpOnDemandTracker from '~/mixins/tracker/top-up-on-demand';
import virtualProductsTracker from '~/mixins/tracker/virtual-product';
import voucher from '~/mixins/tracker/voucher';
import bookkeeping from '~/mixins/tracker/bookkeeping';
import bukabantuanTracker from '~/mixins/tracker/bukabantuan';
import superWarungTracker from '~/mixins/tracker/super-warung';
import { getScreenName } from '~/server/helpers/tracker';


import helpers from './helpers';

const PREFIX = 'agenlite_';

const trackerMixin = {
  computed: {
    ...mapGetters({
      getCart: 'getCart',
      getCurrentAgent: 'getCurrentAgent',
      getTrackerJourneyId: 'getTrackerJourneyId',
      routeReferrer: 'getRouteReferrer',
    }),
    getTrackerReferrerData() {
      return {
        referrer: (this.routeReferrer && this.routeReferrer.name) || '',
        referrer_url: (this.routeReferrer && this.routeReferrer.url) || '',
        current_url: this.$route.fullPath,
        trigger_time: new Date().getTime(),
      };
    },
    trackerVendor() {
      return this.$blTracker.tracker;
    },
  },
  methods: {
    trackClick(_event, eventName) {
      // TODO: track super detail of device spec on Tracker
      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}click`,
        click_source: eventName,
        referrer: this.$route?.name,
      });
    },
    trackEvent(eventCategory, eventAction, eventLabel = '', eventValue = 0) {
      const trackingData = {
        evn: `${PREFIX}event_${eventCategory}`,
        screen_name: this.$route?.name,
        action: eventAction,
        event_label: eventLabel,
        event_value: `${eventValue}`,
      };

      if (eventLabel === '') {
        delete trackingData.event_label;
        delete trackingData.event_value;
      }

      this.$blTracker.actions.trackActions(trackingData);
    },
    trackSaldo(event, eventData) {
      // TODO: track super detail of device spec on Tracker

      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}topup`,
        value: eventData.nominal,
        product_id: eventData.method,
        invoice_id: `${eventData.invoice}`,
        status: event,
      });
    },
    trackCategory(_event, category) {
      // TODO: track super detail of device spec on Tracker

      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}category`,
        category_id: `${category.id}`,
        category_name: category.name,
        screen_name: this.$route?.name,
      });
    },
    trackVP(vpType, eventData) {
      // TODO: track super detail of device spec on Tracker

      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}${vpType}`,
        screen_name: this.$route?.name,
        value: eventData.value,
        product_id: `${eventData.package}`,
        // customer_number: eventData.id, supplied no more
        status: eventData.status,
        invoice_id: `${eventData.invoice}`,
      });
    },
    trackDebtNotesEntryClick({ clickSource, clickSourceStatus, debtAmount, entryAmount, offlineCustomerId, debtSnapshotId }) {
      const payload = {
        evn: 'agenlite_saas_entry_debt_notes_click',
        click_source: clickSource,
        click_source_status: clickSourceStatus || 'success',
        debt_amount: debtAmount || 0,
        entry_amount: entryAmount || 0,
        flintstone_customer_id: offlineCustomerId || 0,
        debt_snapshot_id: debtSnapshotId || 0,
        journey_id: this.getTrackerJourneyId,
        referrer_screen: this.routeReferrer?.name || '',
        referrer_url: this.routeReferrer?.url || '',
        current_screen: getScreenName(this.$route),
      };
      this.$blTracker.actions.trackActions(payload);
    },
    trackDGXClick(data, menu = '') {
      const contentEvn = ['agenlite_click_receipt_transaction_detail', 'agenlite_click_tambah_kontak_pelanggan'];
      const trackingData = {
        session_id: data.sessionId,
        session_time: Math.round((getCurrentTime() - data.sessionTimeStart) / 1000),
        screen_name: this.$route?.name,
        evn: data.evn,
        organic: data.organic,
        user_phone_number: data.userPhoneNumber ? sha256(data.userPhoneNumber) : '',
        agent_type: data.agentType,
      };
      if (menu) trackingData.product_category = menu;
      if (data.referrerName) trackingData.referrer_name = data.referrerName;
      if (contentEvn.includes(data.evn)) {
        Object.assign(trackingData, {
          product_id_partner: data.contentId,
          product_name: data.contentName,
          product_category: data.contentCategory,
          product_quantity: data.contentQuantity,
          invoice_number: data.invoiceNumber,
        });
      }
      this.$blTracker.actions.trackActions(trackingData);
    },
    trackDGX(data) {
      const trackingData = {
        session_id: data.sessionId,
        session_time: Math.round(data.sessionTime / 1000),
        screen_name: this.$route?.name,
        user_phone_number: data.userPhoneNumber ? sha256(data.userPhoneNumber) : '',
        evn: data.evn,
        organic: data.organic,
        currency: data.currency,
        total_purchase: data.value,
        product_type: data.type,
        agent_type: data.agentType,
        payment_method: data.paymentMethod,
      };
      if (data.evn === 'agenlite_click_bayar_product_vp') {
        Object.assign(trackingData, this.fillBayarProductVP(data));
      } else if (data.evn === 'agenlite_load_transaction_detail') {
        Object.assign(trackingData, this.fillLoadTransactionDetail(data));
      }
      this.$blTracker.actions.trackActions(trackingData);
    },
    fillLoadTransactionDetail(data) {
      return {
        product_id_partner: data.contentId,
        product_name: data.contentName,
        product_category: data.contentCategory,
        product_quantity: data.contentQuantity,
        transaction_status: data.status,
        latitude: data.latitude,
        longitude: data.longitude,
        invoice_number: data.invoiceNumber,
        content_type: data.contentType,
        referrer_name: data.referrerName,
        loyalty_poin: data.loyaltyPoin,
      };
    },
    fillBayarProductVP(data) {
      return {
        product_id_partner: data.contentId,
        product_name: data.contentName,
        product_category: data.contentCategory,
        product_quantity: data.contentQuantity,
        kontak_pelanggan_name: data.kontakPelangganName,
        is_kontak_auto_generator_clicked: data.isKontakAutoGeneratorClicked || false,
        invoice_number: data.invoiceNumber,
        content_type: data.contentType,
        referrer_name: data.referrerName,
      };
    },
    trackSpecialPageCheckoutClick(journeyId, cartId, cartItemIds) {
      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}special_page_checkout_clicked`,
        journey_id: journeyId,
        cart_id: `${cartId}`,
        cart_item_ids: cartItemIds,
      });
    },
    trackSpecialPageAddToCartButtonClick(product, promoId, journeyId) {
      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}special_page_add_to_cart_button_clicked`,
        journey_id: journeyId,
        promo_id: `${promoId}`,
        product_id: `${product.id}`,
        product_name: product.name,
      });
    },
    trackSpecialPageSession(specialPageId, journeyId, periode) {
      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}special_page_session`,
        journey_id: journeyId,
        periode: `${periode}`,
        special_page_id: `${specialPageId}`,
      });
    },
    trackSpecialPageGrosirLinkClick(specialPageId, journeyId) {
      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}special_page_grosir_clicked`,
        journey_id: journeyId,
        special_page_id: `${specialPageId}`,
      });
    },
    trackCategoryClick(data) {
      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}category_clicked`,
        journey_id: data.journeyId,
        category_id: `${data.categoryId}`,
        category_name: data.categoryName,
        is_promo: data.isPromo,
        order: `${data.order}`,
      });
    },
    trackCategoryItemAddToCartClick(product, journeyId) {
      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}category_item_add_to_cart_clicked`,
        journey_id: journeyId,
        category_id: `${product.category.id}`,
        category_name: product.category.name,
        product_id: `${product.id}`,
        product_name: product.name,
      });
    },
    trackCategorySession(data) {
      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}category_session`,
        category_id: `${data.categoryId}`,
        category_name: data.categoryName,
        journey_id: data.journeyId,
        is_promo: data.isPromo,
        order: `${data.order}`,
        periode: `${data.periode}`,
      });
    },
    trackHomePageGrosirItemsClick(journeyId) {
      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}homepage_grosir_clicked`,
        journey_id: journeyId,
      });
    },
    trackSaldoBantuan(_event, eventName, { partnerId, programId } = {}) {
      const data = {
        evn: `${PREFIX}saldo_bantuan`,
        referrer: this.$route?.name,
        action: eventName,
      };
      if (partnerId) data.mitra_finance_partner_id = partnerId;
      if (programId) data.mitra_finance_program_detail_id = programId;
      this.$blTracker.actions.trackActions(data);
    },
    trackLuckyDealEntry(entryPoint = '', eventId = '', productId = '') {
      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}mitra_lucky_deal_entry`,
        ui: this.getCurrentAgent.user_id.toString(36),
        journey_id: this.getTrackerJourneyId,
        mld_entry_point: entryPoint,
        mld_event_id: eventId,
        mld_product_id: productId,
        trigger_time: new Date().getTime(),
      });
    },
    trackBukaBantuan(data) {
      this.$blTracker.actions.trackActions({
        evn: `${PREFIX}click_hubungi_bukabantuan`,
        user_phone_number: data.user_phone_number ? sha256(data.user_phone_number) : '',
        user_id: data.user_id,
        session_time: Math.round((getCurrentTime() - data.sessionTimeStart) / 1000),
        session_id: data.session_id,
        invoice_number: data.invoice_number,
        agent_type: data.agent_type,
        organic: data.organic,
        screen_name: data.screen_name,
        created_time: data.created_time,
        paid_time: data.paid_time,
        total_purchase: data.total_purchase,
        transaction_status: data.transaction_status,
        partner: data.partner,
        bank_name: data.bank_name,
        channel: data.channel,
        operator: data.operator,
        product_id_partner: data.product_id_partner,
        product_category: data.product_category,
        product_name: data.product_name,
        product_denom: data.product_denom,
      });
    },

    // New traker format using https://docs.google.com/spreadsheets/d/1zZCpL3ua5fDvcehquvjlOcLdCjn-G1mB4cfR90oZv3g/edit?pli=1#gid=341606546
    // Use separate file to make less clutter for new tracker :)
    // GENERIC
    syncTrackerAttributes() {
      this.$blTracker.actions.syncTrackerAttributes();
    },
    trackOpenedSession({ journeyId, route, referrer }) {
      const payload = {
        evn: `${PREFIX}opened_session`,
        current_page: route.name,
        referrer_screen: referrer.name,
        referrer_url: referrer.url,
        referrer_type: referrer.type,
        source: referrer.type,
        journey_id: journeyId,
      };
      this.$blTracker.actions.trackActions(payload);
    },
    trackScreenVisit({ evn, journeyId, route, referrer, store, params = {}, currentScreen, ...props }) {
      // use data from args if called from middleware
      const payload = {
        evn: evn || `${PREFIX}screen_visit`,
        current_screen: currentScreen || route?.name || this.$route?.name,
        referrer_screen: referrer?.name || this.routeReferrer?.name || '',
        referrer_url: referrer?.url || this.routeReferrer?.url || '',
        journey_id: journeyId || this.getTrackerJourneyId,
        ...props,
      };
      payload.params = helpers.generateVisitParams(store || this.$store, params);
      this.$blTracker.actions.trackActions(payload);
    },
    trackEventClick(action, { currentScreen, targetScreen, value, referrer, clickSourceParam } = {}) {
      const payload = {
        evn: `${PREFIX}event_click`,
        current_screen: currentScreen || this.$route?.name,
        click_source: action,
        referrer_screen: referrer?.name || this.routeReferrer?.name || '',
        referrer_url: referrer?.url || this.routeReferrer?.url || '',
        journey_id: this.getTrackerJourneyId,
      };
      if (targetScreen) payload.target_screen = targetScreen;
      if (value) payload.click_source_status = value; // success, wrong password, etc...
      if (clickSourceParam) payload.click_source_param = clickSourceParam;

      this.$blTracker.actions.trackActions(payload);
    },
    trackNavbarTabClick(action) {
      const payload = {
        evn: `${PREFIX}navbar_click`,
        journey_id: this.getTrackerJourneyId,
        current_screen: this.$route?.name,
        click_source: action,
        referrer_screen: this.routeReferrer?.name ?? '',
      };
      this.$blTracker.actions.trackActions(payload);
    },

    trackAccountClick(action, { targetScreen } = {}) {
      const payload = {
        evn: `${PREFIX}account_click`,
        journey_id: this.getTrackerJourneyId,
        click_source: action,
        referrer_screen: this.$route?.name,
        referrer_url: this.$route.path,
      };

      if (targetScreen) payload.target_screen = targetScreen;

      this.$blTracker.actions.trackActions(payload);
    },

    trackHomepageImpression(action, { sectionPosition = 0, itemPosition = 0, scrollType = '' } = {}) {
      const { name, path } = this.$route;
      const payload = {
        evn: `${PREFIX}homepage_impression`,
        referrer_screen: name,
        referrer_url: path,
        section_name: action,
        section_position: sectionPosition,
        item_position: itemPosition,
        scroll_type: scrollType,
        journey_id: this.getTrackerJourneyId,
      };

      this.$blTracker.actions.trackActions(payload);
    },

    trackTransactionVisit({ filter, totalSaldo } = {}) {
      const payload = {
        evn: `${PREFIX}log_transaction_visit`,
        current_screen: this.$route?.name,
        total_current_saldo: totalSaldo,
        filter,
      };

      this.$blTracker.actions.trackActions(payload);
    },

    trackTransactionDetailVisit({
      type,
      transactionId,
      invoiceId,
      transactionStatus,
      transactionTime,
      paymentMethod,
      productRecoDisplay,
    } = {}) {
      const payload = {
        evn: `${PREFIX}gt_log_transaction_detail_visit`,
        current_screen: this.$route?.name,
        log_title: type,
        transaction_id: transactionId,
        invoice_id: invoiceId,
        transaction_status: transactionStatus,
        transaction_time: transactionTime,
        payment_method: paymentMethod,
        product_reco_display: productRecoDisplay,
      };

      this.$blTracker.actions.trackActions(payload);
    },

    trackGeneralImpression(
      action,
      { sectionPosition = 0, itemName = '', itemPosition = 0, scrollType = '', screen = '', referrer = '', params } = ''
    ) {
      const { $route } = this;
      const trackerPayload = {
        evn: `${PREFIX}general_impression`,
        journey_id: this.$store?.getters.getTrackerJourneyId,
        current_screen: screen || screen?.current || $route.name,
        referrer_screen: screen?.from || this.routeReferrer?.name || '',
        referrer_url: referrer?.url || this.routeReferrer?.url || '',
        scroll_type: scrollType,
        section_name: action,
        section_position: sectionPosition,
        item_name: itemName,
        item_position: itemPosition,
        ...params,
      };
      this.$blTracker.actions.trackActions(trackerPayload);
    },

    ...digitalSendTracker,
    ...loginRegister,
    ...loyaltyPointTracker,
    ...miniFAQ,
    ...profitReports,
    ...topupTracker,
    ...topUpOnDemandTracker,
    ...virtualProductsTracker,
    ...voucher,
    ...bookkeeping,
    ...bukabantuanTracker,
    ...superWarungTracker,
  },
};

export default trackerMixin;
