import Client from '~/types/api/api-module';
import ApiOptions from '../../api-options';

const API_HOST = `${process.env.API_HOST}/_exclusive`;

export default Client.wrap({
  getMitraKYC() {
    return this.get(`${API_HOST}/single-kycs/identities/me?source=Mitra`, 'user', new ApiOptions());
  },
  getStandardizeKYC() {
    return this.get(`${API_HOST}/standardize-kycs/identities/me`, 'user', new ApiOptions());
  },
});
