import { RouteConfig } from '@nuxt/types/config/router';
import { go } from '~/plugins/LocationPlugin';

// these are important/basic need page and keep at app bundle
import PageLogout from '~/pages/System/Logout.vue';
import PageOAuthLogin from '~/pages/System/OAuthLogin.vue';
import PageAuthorize from '~/pages/System/Authorize.vue';
import PageLupa from '~/pages/ResetPassword.vue';
import AutoLoginPage from '~/pages/System/AutoLogin.vue';
import PhoneNumberConfirmation from '~/pages/PhoneNumberConfirmation.vue';

// Modular Router List
import Bookkeeping from '~/router/bookkeeping';
import Campaign from '~/router/campaign';
import Demo from '~/_doc/router-demo';
import Guide from '~/router/guide';
import Leaderboard from '~/router/leaderboard';
import Tutorial from '~/router/tutorial';
import Lite from '~/router/lite';
import Logistics from '~/router/logistics';
import LoyaltyPoint from '~/router/loyalty-point';
import LoyaltyTiering from '~/router/loyalty-tiering';
import PromotionMedia from '~/router/promotion-media';
import Referral from '~/router/referral';
import Register from '~/router/register';
import Sales from '~/router/sales';
import SerbuSeru from '~/router/serbu-seru';
import SuperWarung from '~/router/super-warung';
import TopupKurir from '~/router/topup-kurir';
import VPTix from '~/router/vptix';
import VpIns from '~/router/vpins';
import VpSecondary from '~/router/vpsecondary';
import NewVirtualProducts from '~/router/new-virtual-products';
import KontakPelanggan from '~/router/kontak-pelanggan';
import WinBigEvent from '~/router/win-big-event';
import Subscription from '~/router/subscription';
import MitraBranch from '~/router/mitra-branch';

// utils
import r from '~/utils/render-utils';

// TODO: Split these chunks into spread route/<filename>.js
// public scope
const PageRedirector = () => import(/* webpackChunkName: 'redirector' */ '~/pages/PageRedirector.vue');

// main page & main product
const PageSaldo = () => import(/* webpackChunkName: 'main' */ '~/pages/TambahSaldo.vue');
const PageDataPlan = () => import(/* webpackChunkName: 'main' */ '~/pages/vp/DataPlan.vue');
const PagePhoneCredit = () => import(/* webpackChunkName: 'main' */ '~/pages/vp/PhoneCredit.vue');
const PagePrepaidElectricity = () => import(/* webpackChunkName: 'main' */ '~/pages/vp/PrepaidElectricity.vue');
const PageAgentCustomersSelect = () => import(/* webpackChunkName: 'main' */ '~/pages/AgentCustomers/Select.vue');
const PageAgentCustomersEdit = () => import(/* webpackChunkName: 'main' */ '~/pages/AgentCustomers/Edit.vue');
const PageAgentCustomersDetail = () => import(/* webpackChunkName: 'main' */ '~/pages/AgentCustomers/Detail.vue');
const PagePriceList = () => import(/* webpackChunkName: 'main' */ '~/pages/vp/PriceList.vue');

// administrative page
const PageDeactivated = () => import(/* webpackChunkName: 'main' */ '~/pages/PageDeactivated.vue');
const PageProfile = () => import(/* webpackChunkName: 'main' */ '~/pages/Profile.vue');

// administrative - address page
const AddressConfirmationPage = () => import(/* webpackChunkName: 'address' */ '~/pages/AddressConfirmation.vue');
const NewAddressPage = () => import(/* webpackChunkName: 'address' */ '~/pages/NewAddress.vue');

// dana pages
const DanaActivation = () => import(/* webpackChunkName: 'dana' */ '~/pages/DanaActivation/Index.vue');
const DanaActivationLanding = () => import(/* webpackChunkName: 'dana' */ '~/pages/DanaActivation/Landing.vue');
const DanaActivationCallback = () => import(/* webpackChunkName: 'dana' */ '~/pages/DanaActivation/Callback.vue');
const PageSaldoDana = () => import(/* webpackChunkName: 'dana' */ '~/pages/TambahSaldoDana.vue');
const PageSaldoSelect = () => import(/* webpackChunkName: 'dana' */ '~/pages/TambahSaldoPilih.vue');
const TopupDanaCustomerPage = () => import(/* webpackChunkName: 'dana' */ '~/pages/TopupDanaCustomer.vue');

// others
const BalanceTransferPage = () => import(/* webpackChunkName: 'vp-transfer' */ '~/pages/BalanceTransfer.vue');
const MitraActivationPage = () => import(/* webpackChunkName: 'mitra-activation' */ '~/pages/MitraActivation.vue');

const MitraDeactivation = () => import(/* webpackChunkName: 'main' */ '~/pages/MitraDeactivation.vue');

const ZopimChat = () => import(/* webpackChunkName: 'customer-support'  */ '~/pages/ZopimChatroom.vue');

const PageAhaReward = () => import(/* webpackChunkName: 'aha-reward' */ '~/pages/AhaReward/Main.vue');

const PageUangElektronic = () => import(/* webpackChunkName: 'uang-elektronik' */ '~/pages/UangElektronik/Main.vue');

const PagePaketLangganan = () => import(/* webpackChunkName: 'paket-langganan' */ '~/pages/PaketLangganan/Main.vue');

const PitfallPage = () => import(/* webpackChunkName: 'Dev'  */ '~/pages/System/Pitfall.vue');

const PageInsuranceInfo = () => import(/* webpackChunkName: 'insurance-info' */ '~/pages/Insurance/Info.vue');

export const routes: RouteConfig[] = [
  {
    path: '/logout',
    component: PageLogout,
    name: 'logout',
  },
  {
    path: '/',
    name: 'agenlite_apps_root',
    redirect: { name: 'agenlite_apps_lite_homepage' },
  },
  {
    path: '/login',
    component: PageOAuthLogin,
    name: 'signin',
  },
  {
    path: '/authorize',
    component: PageAuthorize,
    name: 'authorize_page',
  },
  {
    path: '/profile',
    component: r(PageProfile),
    name: 'profile_page',
  },
  {
    path: '/verify',
    beforeEnter(_to, _from, next) {
      go('/aplikasi-mitra');
      next(false);
    },
  },
  {
    path: '/aktivasi-mitra',
    component: r(MitraActivationPage),
    name: 'mitra_activation',
  },
  {
    path: '/lupa-password',
    props: route => ({
      from: route.query.from,
    }),
    component: PageLupa,
    name: 'reset_password',
  },
  {
    path: '/deactivated',
    component: r(PageDeactivated),
    name: 'deactiavated_page',
  },
  {
    path: '/batalkan-mitra',
    component: r(MitraDeactivation),
    name: 'mitra_deactivation',
  },
  {
    path: '/isi-saldo/',
    component: r(PageSaldo),
    name: 'top_up_dompet',
    props: route => ({
      paymentMethod: route.query.payment_method,
    }),
  },
  {
    path: '/isi-saldo/dana',
    component: r(PageSaldoDana),
    name: 'top_up_dompet_dana',
  },
  {
    path: '/isi-saldo/pilih',
    component: r(PageSaldoSelect),
    name: 'top_up_dompet_select',
  },
  {
    path: '/pulsa',
    component: r(PagePhoneCredit),
    props: true,
    name: 'vp_pulsa',
  },
  {
    path: '/paket-data',
    component: r(PageDataPlan),
    props: true,
    name: 'vp_paketdata',
  },
  {
    path: '/token-listrik',
    component: r(PagePrepaidElectricity),
    props: true,
    name: 'vp_tokenlistrik',
  },
  {
    path: '/confirm-address',
    component: r(AddressConfirmationPage),
    name: 'confirm_address',
  },
  {
    path: '/new-address',
    component: r(NewAddressPage),
    name: 'change_address',
  },
  {
    path: '/bantuan-agen',
    component: r(ZopimChat),
    name: 'bukabantuan_agen',
    children: [
      {
        path: 'inbox',
        component: r(ZopimChat),
        name: 'bukabantuan_inbox',
      },
      {
        path: 'inbox/:id',
        component: r(ZopimChat),
        name: 'bukabantuan_inbox_detail',
      },
    ],
  },
  {
    path: '/kirim-saldo',
    component: r(BalanceTransferPage),
    name: 'vp_kirimsaldo',
  },
  {
    path: '/agent-customers/detail',
    component: r(PageAgentCustomersDetail),
    props: true,
    name: 'agent_customers_detail',
  },
  {
    path: '/agent-customers/edit',
    component: r(PageAgentCustomersEdit),
    props: true,
    name: 'agent_customers_edit',
  },
  {
    path: '/agent-customers/select',
    component: r(PageAgentCustomersSelect),
    props: true,
    name: 'agent_customers_select',
  },
  {
    path: '/:vp/pricelist',
    component: r(PagePriceList),
    name: 'price_list',
  },
  {
    path: '/konfirmasi-telepon',
    component: PhoneNumberConfirmation,
    name: 'phone_number_confirmation',
    props: route => ({
      redirectUrl: route.query.redirect_url,
    }),
  },
  {
    path: '/aktivasi-dana',
    component: r(DanaActivation),
    children: [
      {
        path: '',
        component: r(DanaActivationLanding),
        name: 'bukadana_activation',
      },
      {
        path: 'callback',
        component: r(DanaActivationCallback),
        name: 'bukadana_activation_callback',
        props: route => ({
          linked: route.query.linked === 'true',
          authCode: route.query.auth_code || route.query.authCode,
          state: route.query.state,
        }),
      },
    ],
  },
  {
    path: '/misi-calon-juragan/reward',
    component: r(PageAhaReward),
    name: 'aha_reward',
  },
  {
    path: '/paket-langganan',
    component: r(PagePaketLangganan),
    name: 'paket_langganan',
  },
  {
    path: '/uang-elektronik/non-nfc-instruction',
    component: r(PageUangElektronic),
    name: 'uang-elektronik-non-nfc-instruction',
  },
  {
    path: '/topup-dana-customer',
    component: r(TopupDanaCustomerPage),
    name: 'topup_dana_customer',
  },
  {
    path: '/autologin',
    component: AutoLoginPage,
    name: 'autologin',
    props: route => ({
      accessObject: route.query.access,
    }),
  },
  {
    path: '/insurance/info',
    component: r(PageInsuranceInfo),
    name: 'insurance_info',
  },
  // spread modules list
  ...Subscription,
  ...WinBigEvent,
  ...Campaign,
  ...Bookkeeping,
  ...Guide,
  ...Leaderboard,
  ...Lite,
  ...Logistics,
  ...LoyaltyPoint,
  ...LoyaltyTiering,
  ...MitraBranch,
  ...PromotionMedia,
  ...Referral,
  ...Register,
  ...Sales,
  ...SerbuSeru,
  ...SuperWarung,
  ...TopupKurir,
  ...Tutorial,
  ...VPTix,
  ...VpIns,
  ...VpSecondary,
  ...NewVirtualProducts,
  ...KontakPelanggan,
  ...(process.env.NODE_ENV === 'development' ? Demo : []),
  ...(process.env.NODE_ENV === 'development'
    ? [
        {
          path: '/_dev/pitfall',
          component: r(PitfallPage),
          name: 'dev_pitfall',
        },
      ]
    : []),

  // THIS ROUTE SHOULD ALWAYS BE PLACED AT THE BOTTOM OF THIS LIST FOR 404 REDIRECT
  {
    path: '*',
    component: r(PageRedirector),
    name: 'redirector_page',
  },
];

export default routes;
