import { PublicCreateCustomerTransactionRequest } from '@bukalapak/openapi-schema/vptrader/transaction/v2/public/transaction-domain';
import ApiOptions from '~/api/v4/api-options';
import Client from '~/types/api/api-module';

const BASE_URL = `${process.env.API_VPNONTRADER_HOST}/v2/customer/transactions`;

export default Client.wrap({
  createCustomerTransaction(requestPayload: PublicCreateCustomerTransactionRequest) {
    return this.post(`${BASE_URL}`, 'transaction.*.*', {
      enableAuthHeader: true,
      ...new ApiOptions({ requestPayload }),
    });
  },
});
